<template>
<!-- Registro exitoso Modal -->
<!-- Button trigger modal -->
<button id="registro_popup_btn" style="display: none" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#avisoModal">
</button>
<div class="modal fade" id="avisoModal" tabindex="-1" aria-labelledby="avisoModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title fs-5" id="avisoModalLabel">Aviso de privacidad</h1>
				<button type="button" @click="gotoHome" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>Aviso de privacidad</p>
<p>POL&Iacute;TICA DE PRIVACIDAD DE &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo;</p>
<p>&ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; ha redactado esta pol&iacute;tica de privacidad para demostrar su firme compromiso en relaci&oacute;n con la privacidad. A continuaci&oacute;n, se enumeran las pr&aacute;cticas de recopilaci&oacute;n y difusi&oacute;n de la informaci&oacute;n de &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; con respecto a la informaci&oacute;n que se proporciona a El Sitio. Recomendamos al usuario que lea esta pol&iacute;tica antes de acceder al Sitio Web. El acceso a este Sitio implica conformidad con los t&eacute;rminos de la pol&iacute;tica. Si no est&aacute; de acuerdo con alg&uacute;n punto, por favor no acceda El Sitio (plataforma de torneos).</p>
<p>DECLARACI&Oacute;N DE PRIVACIDAD</p>
<p>Recopilaci&oacute;n y uso de informaci&oacute;n por parte de &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; Informaci&oacute;n personal de identificaci&oacute;n</p>
<p>Se recopilar&aacute; informaci&oacute;n personal que le identifique, tal como: fecha de nacimiento, nombre, sexo, direcci&oacute;n, c&oacute;digo postal, n&uacute;mero de tel&eacute;fono o de celular, la direcci&oacute;n de correo electr&oacute;nico, cu&aacute;ndo la proporciona a trav&eacute;s de este Sitio, por ejemplo, al crear una cuenta, participar en un torneo, suscribirse a boletines, participar en encuestas, ponerse en contacto con el servicio de atenci&oacute;n al cliente o con cualquier otra interacci&oacute;n con el Sitio Web. Es posible que utilicemos su informaci&oacute;n personal identificable para todos los fines legales, los cuales pueden incluir, pero sin limitarse a, responder sus solicitudes, procesar sus transacciones, para fines administrativos tales como llevar a cabo concursos, averiguar las partes del Sitio que son de su inter&eacute;s, procesar premios. Si selecciona participar, podemos compartir su informaci&oacute;n identificable personal con El Patrocinador o entidades afiliadas o subsidiarias ("Entidades afiliadas") de &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; con &ldquo;EL ORGANIZADOR&rdquo; para los fines de enviarle informaci&oacute;n sobre los programas de &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo;. o sus entidades afiliadas (Mondelez M&eacute;xico S. de R.L. de C.V. y , en ocasiones, publicidad y material promocional de algunos de los socios estrat&eacute;gicos y publicitarios. Tambi&eacute;n se puede usar la informaci&oacute;n personal que le identifique para fines empresariales internos, por ejemplo, optimizar sus experiencias en el Sitio y analizar y administrar otros asuntos.</p>
<p>Nota sobre el uso de este Sitio por parte de ni&ntilde;os.</p>
<p>Por lo anterior, el menor de edad reconoce y acepta que es requisito indispensable contar con la autorizaci&oacute;n del padre, madre o tutor para poder partir en el torneo, por lo que EL PATROCINADOR/ EL ORGANIZADOR DEL EVENTO (&ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo;) podr&aacute; verificar la veracidad de dicha autorizaci&oacute;n y en su caso determinar la participaci&oacute;n o no participaci&oacute;n del menor de edad en el torneo.</p>
<p>Todos estos datos se compartir&aacute;n con el desarrollador y patrocinador del &ldquo;TORNEO&rdquo; &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo;, el cual usar&aacute; estos datos para lo consecuente:</p>
<p>Informaci&oacute;n demogr&aacute;fica</p>
<p>Asimismo, se recopilar&aacute;n datos demogr&aacute;ficos estad&iacute;sticos como, por ejemplo, la fecha de nacimiento, el sexo o el c&oacute;digo postal. La informaci&oacute;n de car&aacute;cter demogr&aacute;fico se puede utilizar para optimizar su experiencia en este Sitio, al mostrarte contenido espec&iacute;fico, incluidos eventos especiales y publicidad en los que pueda estar interesado, as&iacute; como para ver contenido conforme al c&oacute;digo postal.</p>
<p><br />En algunas ocasiones, la informaci&oacute;n consolidada que no identifica a ning&uacute;n usuario en particular se re&uacute;ne y comparte con empresas subsidiarias o afiliadas a &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo;, socios estrat&eacute;gicos, comerciantes y anunciantes.</p>
<p>Recopilaci&oacute;n pasiva de informaci&oacute;n no personal</p>
<p>A menudo este Sitio exige el uso de cookies codificadas o no codificadas. Las cookies son datos que un servidor Web transfiere a la computadora a modo de registro. Las cookies son de utilizaci&oacute;n com&uacute;n en el sector por pr&aacute;cticamente la totalidad de Sitios Web y facilitan al usuario el acceso y la utilizaci&oacute;n de Sitios concretos, suministrando informaci&oacute;n que se puede utilizar para ofrecer contenido concreto adecuado a sus intereses y para otros fines como la seguridad y dem&aacute;s funciones de administraci&oacute;n de cuentas, que pueden dar lugar al seguimiento de informaci&oacute;n personal de identificaci&oacute;n.</p>
<p>Las cookies no causan da&ntilde;os a la computadora y &uacute;nicamente el Sitio Web que las transfiere puede leerlas, modificarlas o borrarlas. Si no quiere que se recopile ninguna informaci&oacute;n por medio de cookies, la mayor&iacute;a de los navegadores tienen sistemas sencillos que permiten eliminarlas, rechazarlas autom&aacute;tica o directamente le permiten elegir si acepta o no la transferencia de cookies a su computadora. Consulte las instrucciones de su navegador o la pantalla de ayuda para obtener m&aacute;s informaci&oacute;n acerca de estas funciones. Debe tener en cuenta, no obstante, que si no acepta cookies puede resultar dif&iacute;cil o imposible acceder a determinadas partes del Sitio.</p>
<p>Algunas de las funciones de las cookies de un sitio est&aacute;n controladas por entidades afiliadas a quien la empresa concede la gesti&oacute;n de determinados programas y el cumplimiento de peticiones concretas de visitantes y clientes. &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; exige a estas entidades que restrinjan la utilizaci&oacute;n de cookies a los t&eacute;rminos estipulados en la presente pol&iacute;tica, pero no se hace responsable de la utilizaci&oacute;n que terceras partes hagan de las cookies.</p>
<p>El Sitio puede hacer uso de "p&iacute;xeles transparentes", "rastreadores web" o "gifs transparentes" (englobados como "p&iacute;xeles transparentes") para recopilar estad&iacute;sticas sobre la utilizaci&oacute;n del Sitio y los &iacute;ndices de respuesta. Los p&iacute;xeles transparentes permiten contabilizar los usuarios que han visitado determinadas p&aacute;ginas del Sitio, proporcionar servicios corporativos y ayudar a determinar la efectividad de campa&ntilde;as promocionales. Si se utilizan mensajes de correo electr&oacute;nico en formato HTML, los p&iacute;xeles transparentes pueden informar al remitente de cu&aacute;ndo ha sido abierto el mensaje.</p>
<p>&ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; tambi&eacute;n recaba informaci&oacute;n sobre &ldquo;las referencias", direcciones IP y distintas variables del entorno.</p>
<p>Una "referencia" es informaci&oacute;n que el explorador Web transmite a nuestros servidores Web y que hace alusi&oacute;n al URL desde el que accede al Sitio.</p>
<p>La "direcci&oacute;n IP" es un n&uacute;mero que utilizan los equipos en una red para identificar el equipo en cuesti&oacute;n de modo que la informaci&oacute;n se pueda transmitir a &eacute;ste.</p>
<p>Las "variables del entorno" son, entre otras cosas, el dominio desde el que se accede a Internet, la hora de acceso al Sitio, el tipo de explorador Web, el sistema operativo o plataforma que se utilizan, la direcci&oacute;n de Internet o del Sitio Web que le remite a este Sitio, los nombres de las p&aacute;ginas del Sitio que visita, as&iacute; como la direcci&oacute;n de Internet del Sitio Web que visita a continuaci&oacute;n.</p>
<p>Esta informaci&oacute;n se recopila para uso interno de &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; por ejemplo, para incrementar la seguridad del Sitio Web, detectar tendencias demogr&aacute;ficas, ayudar a &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; a ofrecer el contenido que m&aacute;s se ajusta a sus intereses y, por &uacute;ltimo, para mejorar de cualquier otra forma su experiencia en este Sitio. <br />Es posible que la informaci&oacute;n tambi&eacute;n se comparta con terceros a nivel general, sin incluir sus datos de identificaci&oacute;n.</p>
<p>Proveedores de servicios.</p>
<p>Es posible que se contraten los servicios de proveedores externos para ayudarnos en la operaci&oacute;n del negocio y del Sitio o para gestionar actividades en nuestro nombre como, por ejemplo, el env&iacute;o de correos electr&oacute;nicos, la realizaci&oacute;n de pedidos y la administraci&oacute;n de torneos. Es posible que compartamos su informaci&oacute;n con estas terceras partes en los casos anteriormente descritos. Por ejemplo, si participa en un torneo, juego o programa de lealtad y, como consecuencia, obtiene un premio, proporcionaremos informaci&oacute;n personal a las agencias que se encargan de la gesti&oacute;n y el proceso de juegos y mercanc&iacute;as.</p>
<p><br />ENLACES Y PR&Aacute;CTICAS DE PRIVACIDAD DE TERCEROS</p>
<p>Este Sitio puede contener enlaces a otros sitios ajenos a &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo;, consecuentemente esta &uacute;ltima NO ES RESPONSABLE DE LAS PR&Aacute;CTICAS DE PRIVACIDAD O EL CONTENIDO DE LOS SITIOS WEB DE TERCEROS, INCLUIDOS LOS SITIOS WEB DE ENTIDADES AFILIADAS. Dichos enlaces se proporcionan exclusivamente para su comodidad. La inclusi&oacute;n de un enlace en el sitio no implica que &ldquo;&ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; apoye al sitio al que remite el enlace. Si facilita informaci&oacute;n personal de identificaci&oacute;n a trav&eacute;s de cualquier sitio Web de un tercero de ese tipo, su transacci&oacute;n tendr&aacute; lugar en el sitio Web de dicho tercero (no en este sitio) y la informaci&oacute;n personal de identificaci&oacute;n que facilite ser&aacute; recopilada por dicho tercero y se regir&aacute; por la pol&iacute;tica de privacidad del mismo. &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; le invita a revisar la pol&iacute;tica de privacidad de los Sitios Web de terceras partes a los que haya accedido a trav&eacute;s de los enlaces de este Sitio.</p>
<p>SEGURIDAD</p>
<p>Este Sitio posee medidas de seguridad para ayudar a la protecci&oacute;n contra la p&eacute;rdida, el uso incorrecto y la modificaci&oacute;n de la informaci&oacute;n personal de identificaci&oacute;n que se encuentra bajo el control del mismo. &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; emplea medidas de seguridad acordes con las pr&aacute;cticas est&aacute;ndar del sector para ayudar a proteger contra el acceso p&uacute;blico a la informaci&oacute;n personal de identificaci&oacute;n y bancaria que &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; recopile y utilice en este sitio. Por ejemplo, el acceso a nuestra base de datos en la que se guarda la informaci&oacute;n personal de identificaci&oacute;n queda registrado con fines de seguridad y el acceso est&aacute; restringido al personal clave. Algunas actividades espec&iacute;ficas est&aacute;n protegidas mediante el uso de la tecnolog&iacute;a de codificaci&oacute;n de comunicaciones seguras denominada SSL. SSL (del ingl&eacute;s Secure Sockets Layer, nivel de sockets seguro) es un protocolo que oculta la informaci&oacute;n para evitar que otras personas intercepten y lean los datos que se env&iacute;an entre el cliente (su equipo) y el servidor (este Sitio). La informaci&oacute;n personal de identificaci&oacute;n y la actividad de cuentas tambi&eacute;n est&aacute;n protegidas mediante el uso de nombres de usuario y contrase&ntilde;as. Para ayudar a preservar la seguridad de la informaci&oacute;n, es necesario que usted mantenga en secreto su nombre de usuario y su contrase&ntilde;a.</p>
<p>DARSE DE ALTA</p>
<p>Este Sitio ofrece al usuario la oportunidad de darse de alta para recibir mensajes de comunicados de mercadotecnia de &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; Si no se da de alta, &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; no le enviar&aacute; ofertas ni promociones. &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; Tambi&eacute;n le ofrece m&eacute;todos sencillos para eliminar la informaci&oacute;n de la base de datos, de modo que no reciba comunicaciones futuras, o para cerrar la cuenta.<br />Puede darse de baja en la p&aacute;gina de perfil de cuenta:<br />Tenga en cuenta que las modificaciones no entrar&aacute;n en vigor de forma inmediata. En cualquier caso, deber&aacute; indicar claramente la informaci&oacute;n que desea que modifiquemos o exponer con claridad que desea que su informaci&oacute;n personal identificable sea eliminada de nuestra base de datos. Trataremos de satisfacer su petici&oacute;n lo antes posible. Tenga en cuenta que a pesar de cualquier solicitud para eliminar o modificar la informaci&oacute;n personal de identificaci&oacute;n, es posible que tengamos que conservar determinada informaci&oacute;n a modo de registro, y tambi&eacute;n podr&iacute;a haber informaci&oacute;n residual que permanezca dentro de nuestras bases de datos u otros registros, que no sea eliminada o modificada. Asimismo, nos reservamos el derecho de ponernos en contacto con antiguos usuarios del sitio, ocasionalmente, para usos administrativos importantes.</p>
<p>ACTUALIZACI&Oacute;N DE ESTA POL&Iacute;TICA DE PRIVACIDAD</p>
<p>&ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; se reserva el derecho de realizar las modificaciones que estime oportunas en esta Pol&iacute;tica de privacidad. En el caso de que &ldquo;GAMING TRAINERS S.A. DE C.V.&rdquo; efect&uacute;e un cambio significativo en su Pol&iacute;tica de privacidad, &eacute;ste se har&aacute; p&uacute;blico en el presente documento. Le recomendamos visitar con frecuencia esta p&aacute;gina, ya que el uso del Sitio tras cualquier modificaci&oacute;n que se lleve a cabo en la presente Pol&iacute;tica de privacidad constituye la aceptaci&oacute;n de tales cambios.</p>
<p>LEGISLACI&Oacute;N APLICABLE</p>
<p>La presente Pol&iacute;tica de privacidad est&aacute; sujeta a las leyes de la Rep&uacute;blica Mexicana, excluyendo cualquier disposici&oacute;n de selecci&oacute;n de fuero.</p>
<p>DATOS DE CONTACTO DE GAMING TRAINERS S.A. DE C.V.</p>
<p>Correo de contacto: contacto@esports-latam.com<br />Tel&eacute;fono de contacto: 800 715 2022</p>
<p>DERECHOS ARCO, REVOCACI&Oacute;N DEL CONSENTIMIENTO Y/O LIMITACI&Oacute;N DEL USO O DIVULGACI&Oacute;N</p>
<p>GAMING TRAINERS S.A. DE C.V. pone a disposici&oacute;n de Usted, el siguiente procedimiento a efecto de que pueda ejercer sus derechos ARCO o pueda revocar su consentimiento y/o limitar el uso o divulgaci&oacute;n de sus Datos Personales:</p>
<p>Enviar un escrito libre al siguiente correo electr&oacute;nico contacto@gaming-trainers.com<br />o entregarlo en el domicilio ubicado en CALLE COMERCIANTES # 5395 COL. JARDINES DE GUADALUPE ZAPOPAN, JALISCO.C.P. 45030 a la atenci&oacute;n de nuestro responsable ARCO que contenga: (i) el derecho que desea ejercitar o la manifestaci&oacute;n de que desea revocar su consentimiento para el Tratamiento de sus Datos Personales o limitar su uso o divulgaci&oacute;n; y (ii) la fecha, v&iacute;a y lugar mediante los cuales nos proporcion&oacute; sus Datos Personales.<br />Anexar (i) copia de su identificaci&oacute;n oficial en caso de actuar por su propio derecho; o copia del poder notarial de su representante legal o apoderado, as&iacute; como copia de la identificaci&oacute;n oficial de dicho representante; (ii) en caso de contar con &eacute;l, copia del Aviso de Privacidad con el que cuente, o la manifestaci&oacute;n de no contar con ninguno; y<br />(iii) correo electr&oacute;nico o direcci&oacute;n a la cual desea recibir su respuesta.<br />Su solicitud ser&aacute; respondida en un plazo no mayor a 20 d&iacute;as mediante un escrito que se le enviar&aacute; al domicilio que haya manifestado en su solicitud o al correo electr&oacute;nico de su preferencia, seg&uacute;n nos lo haya indicado; y en caso de ser procedente, la procesaremos en un plazo no mayor a 15 d&iacute;as a que le hayamos enviado la respuesta a su solicitud.</p>
			</div>
			<div class="modal-footer"></div>
		</div>
	</div>
</div>
</template>

<script>
	
	export default {
		mounted() {
			this.showRegisterSuccessPopup();
			
		},
		
		methods: {
			gotoHome() {
				window.location.href="/";
			},
			showRegisterSuccessPopup() {
				
				document.getElementById("registro_popup_btn").click();
			}
		}
	}

</script>

<style scoped>
	.modal-header, .modal-footer {
			background-image: url('../assets/rivals/arriba_mensaje.png');
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 63px;
		}

		.modal-title {
			width: 100%;
		}

		.modal-body {
			font-family: "Arial";
			font-size: 1.2rem;
			padding: 3vw 6vw;
			color: black;
			text-align: left;
		}

		.modal-body h1, .modal-body span, .modal-body strong, .modal-body p{
			font-size: 1.2rem;
			font-family: "Arial";
			font-weight: bold;
		}

		.modal-body p {
			font-weight: normal;
		}

		#modal_link {
			margin: 40px;
			word-break: break-all;
		}
</style>