<template>
	
	<div class="home-wrapper section" id="home">
		<div class="home-content">
			<div class="registro-home-btn">
				<button @click="goto('registro')" class="registro_btn">REGÍSTRATE</button>
			</div>
		</div>
	</div>

	<!-- //// NOVEDADES //// -->
	<div class="transmition-box section" id="hall_of_fame">
		<h1>Gana un viaje a USA para inaugurar tu propia estatua en el Metaverso</h1>
		<div style="position: relative; margin-top: 40px;">
			<picture>
				<source srcset="../assets/rivals/video_mask.webp" type="image/webp">
				<source srcset="../assets/rivals/video_mask.png" type="image/png">
				<img style="width: 112%; position: absolute; pointer-events: none; left: -5%; margin-top: -2%; z-index: 2;" src="../assets/rivals/video_mask.png">
			</picture>
			
		</div>
		<div style="text-align: justify;">
			<h3 class="title" style="text-align: center;"></h3>
			<div>
				<div style="position:relative;padding-top:56.25%;">
					<div style="position:absolute;top:0;left:0;width:100%;height:100%; z-index: 0;">
						<video style="width:100%" controls>
							<source src="/walkthrough.mp4" type="video/mp4">
								Your browser does not support the video tag.
							</video>
						</div>
					</div>
				</div>
			</div>
		</div>

	<!-- //// JUEGOS //// -->
	<!--
	<div class="challenge-wrapper section" id="juegos">
		<div style="display: flex; flex-direction: column;">
			<div class="challenge-box">
				
				<div class="paso">
					<picture>
						<source srcset="../assets/rivals/cod.webp" type="image/webp">
						<source srcset="../assets/rivals/cod.png" type="image/png">
						<img src="../assets/rivals/cod.png">
					</picture>
				</div>
				<div class="paso">
					<picture>
						<source srcset="../assets/rivals/fortnite.webp" type="image/webp">
						<source srcset="../assets/rivals/fortnite.png" type="image/png">
						<img src="../assets/rivals/fortnite.png">
					</picture>
				</div>
				<div class="paso">
					<picture>
						<source srcset="../assets/rivals/fallguys.webp" type="image/webp">
						<source srcset="../assets/rivals/fallguys.png" type="image/png">
						<img src="../assets/rivals/fallguys.png">
					</picture>
				</div>
			</div>
			<div style="display: flex; align-items: center; justify-content: center;">
				<picture>
					<source srcset="../assets/rivals/grafico_izquierda.webp" type="image/webp">
					<source srcset="../assets/rivals/grafico_izquierda.png" type="image/png">
					<img src="../assets/rivals/grafico_izquierda.png" style="margin-right: -60px;">
				</picture>

				<button @click="goto('funciona')" class="registro_btn" style="margin: 0px 0px !important; z-index: 2;">¿CÓMO FUNCIONA?</button>
				<picture>
					<source srcset="../assets/rivals/grafico_derecha.webp" type="image/webp">
					<source srcset="../assets/rivals/grafico_derecha.png" type="image/png">
					<img src="../assets/rivals/grafico_derecha.png" style="margin-left: -60px;">
				</picture>
			</div>
		</div>
		<div style="display: flex; justify-content: flex-end; margin-top: -250px">
			<picture>
				<source srcset="../assets/rivals/grafico1.webp" type="image/webp">
				<source srcset="../assets/rivals/grafico1.png" type="image/png">
				<img src="../assets/rivals/grafico1.png">
			</picture>
		</div>
	</div>
	-->

	<!-- //// COMO FUNCIONA //// -->
	<!--
	<div class="funciona-wrapper section" style="position: relative;" id="funciona">

		<div class="title" style="position: relative; z-index: 2">
			<h1>PARA ASISTIR A LA VIEWING PARTY SIGUE LOS SIGUIENTES PASOS:</h1>
		</div>
		<div style="display: flex; justify-content: flex-start; margin-top: -100px">
			<picture>
				<source srcset="../assets/rivals/grafico1.webp" type="image/webp">
				<source srcset="../assets/rivals/grafico1.png" type="image/png">
				<img src="../assets/rivals/grafico1.png">
			</picture>
		</div>
		<br>
		<div class="tres-pasos" style="margin-top: -100px; margin-bottom: 10vw;">
			<div class="paso">
				<div class="description dsc-1">
					<p>Pre registrate en el formulario y elige a que mini torneo presencial asistirás.</p>
				</div>
				<h2>PASO 1</h2>
			</div>

			<div class="paso">
				<div class="description dsc-2">
					<p>Te enviaremos un correo confirmando tu invitación a la Viewing Party.</p>
				</div>
				<h2>PASO 2</h2>
			</div>

			<div class="paso">
				<div class="description dsc-3">
					<p>Al tener tu invitación por correo, estarás listo para asistir.</p>
				</div>
				<h2>PASO 3</h2>
			</div>
		</div>
		<div style="position:absolute; width: 100%; padding-right: 22vw; top: 40vh;">
			<div style="display: flex; justify-content: flex-end; width: 100%">
				<picture>
					<source srcset="../assets/rivals/bocas_juntas.webp" type="image/webp">
					<source srcset="../assets/rivals/bocas_juntas.png" type="image/png">
					<img src="../assets/rivals/bocas_juntas.png" style="width: 20vw">
				</picture>
			</div>
		</div>
		<div class="registro-funciona-btn">
			<button @click="goto('registro')" class="registro_btn">REGÍSTRATE</button>
		</div>
		<div style="font-size: 1.2rem; color: black; font-family: 'Montserrat';">*El pre registro no asegura tu invitación a la Viewing Party, hasta recibir tu invitación por correo.</div>
	</div>
	-->

	<canvas id="my-canvas"></canvas>
	<!-- //// TRANSMISION //// -->
	<div class="transmition-box" id="transmision">
		<div style="text-align: justify; margin-top: 4vh; padding: 0px 1vw;">
			<h3 class="title" style="text-align: center;"></h3>
			<div class="video-final" style="padding: 40px 0px;">
				<div style="position:relative;padding-top:56.25%;">
					<iframe
					:src="twitch_host"
					frameborder="0"
					scrolling="no"
					allowfullscreen="true"
					style="position:absolute;top:0;left:0;width:100%;height:100%; border:6px solid var(--pink); z-index: 0;">
					</iframe>

					<!--

					<iframe style="position:absolute;top:0;left:0;width:100%;height:100%; border:6px solid var(--pink); z-index: 0; " src="https://www.youtube.com/embed/7o342f7qy0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->


				</div>
			</div>
		</div>
	</div>
	<img src="https://rivalstrident.com/bocas/confeti_1.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_2.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_3.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_4.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_5.png" style="display: none">
	<div class="transmision-buttons">
		<a href="/transmision-especial">TRANSMISÓN ESPECIAL</a>
		<button @click="showConfetti">CELEBRA</button>
	</div>


	<!-- //// TALENTOS //// -->
	<div class="talentos-wrapper section" id="talento">
		<div class="talentos-box">
			<div  v-for="(talento, index) in talentos" :key="index" class="talento" :class="{'margin-up': index % 2!==0}">
				<div style="position: relative;">
					<div class="talento-redes">
						<div class="talento-redes-links">
							<a :href="talento.redes.fb" target="_blank"><i class="fab fa-facebook-f"></i></a>
							<a :href="talento.redes.ig" target="_blank"><i class="fab fa-instagram"></i></a>
							<a :href="talento.redes.tt" target="_blank"><i class="fab fa-twitter"></i></a>
						</div>
						<picture>
							<source :srcset="`${talento.images.redes}.webp`" type="image/webp">
							<source :srcset="`${talento.images.redes}.png`" type="image/png">
							<img style="position: absolute; left: 0; z-index: 0;" :src="`${talento.images.redes}.png`">
						</picture>
						
					</div>
					<picture>
						<source :srcset="`${talento.images.normal}.webp`" type="image/webp">
						<source :srcset="`${talento.images.normal}.png`" type="image/png">
						<img class="talento-img-normal" :src="`${talento.images.normal}.png`">
					</picture>
					
				</div>
				<h2>{{talento.nombre}}</h2>
			</div>
			
		</div>
	</div>

	<!-- //// PREMIOS //// -->
	<div class="premios-wrapper section" id="premios">
		<div class="title" style="position: relative; z-index: 2">
			<h1>BOLSA DE MÁS DE $180,000</h1>
			<h1 style="font-size: 1.4rem">Un viaje todo pagado a USA para el MVP de cada torneo</h1>
		</div>
		<div style="display: flex; justify-content: flex-start; margin-top: -100px">
			<picture>
				<source srcset="../assets/rivals/grafico1.webp" type="image/webp">
				<source srcset="../assets/rivals/grafico1.png" type="image/png">
				<img src="../assets/rivals/grafico1.png">
			</picture>
			
		</div>
		<br>
		<div class="tres-pasos" style="margin-top: -100px">
			<div class="paso">
				<div>
					<picture>
						<source srcset="../assets/rivals/premio_fortnite_01.webp" type="image/webp">
						<source srcset="../assets/rivals/premio_fortnite_01.png" type="image/png">
						<img src="../assets/rivals/premio_fortnite_01.png">
					</picture>
				</div>
				<h2>1er LUGAR<br>FORTNITE</h2>
			</div>

			<div class="paso">
				<div>
					<picture>
						<source srcset="../assets/rivals/premio_cod_01.webp" type="image/webp">
						<source srcset="../assets/rivals/premio_cod_01.png" type="image/png">
						<img src="../assets/rivals/premio_cod_01.png">
					</picture>
				</div>
				<h2>1er LUGAR<br>COD MOBILE</h2>
			</div>

			<div class="paso">
				<div>
					<picture>
						<source srcset="../assets/rivals/premio_fallguys_01.webp" type="image/webp">
						<source srcset="../assets/rivals/premio_fallguys_01.png" type="image/png">
						<img src="../assets/rivals/premio_fallguys_01.png">
					</picture>
				</div>
				<h2>1er LUGAR<br>FALL GUYS</h2>
			</div>
		</div>
		<div style="display: flex; justify-content: space-around; margin:  0 auto; width: 70%; margin-top: -10vw; align-items: end;">
			<div style="max-width: 250px; width: 20vw;">
				<picture>
					<source srcset="../assets/rivals/boca1.webp" type="image/webp">
					<source srcset="../assets/rivals/boca1.png" type="image/png">
					<img src="../assets/rivals/boca1.png" style="width: 100%">
				</picture>
				
			</div>
			<div style="max-width: 250px; width: 20vw;">
				<picture>
					<source srcset="../assets/rivals/boca2.webp" type="image/webp">
					<source srcset="../assets/rivals/boca2.png" type="image/png">
					<img src="../assets/rivals/boca2.png" style="width: 100%">
				</picture>
			</div>
		</div>
	</div>

	<!-- //// REGISTRATE //// -->
	<div class="registrate-wrapper section" id="registro">
		<div>
			<div class="registrate-left" style="display: flex; flex-direction: column; max-width: 33vw; padding: 60px">
				<h2 style="text-align: left; font-size: 1.5rem;">
					Asiste a la Viewing Party en Arena Artz Pedregal en CDMX.<br><br>Registrate y ten la oportunidad de participar en alguno de los torneos presenciales.
				</h2>
				<div class="registrate-left-image" style="margin-top: 20px">
					<img style="display: block; max-width: 100%; border: 5px solid white;" src="../assets/rivals/arena_rivals.png" />
				</div>
			</div>
			<div style="display: flex; justify-content: flex-start; margin-top: -100px">
				<picture>
					<source srcset="../assets/rivals/grafico1.webp" type="image/webp">
					<source srcset="../assets/rivals/grafico1.png" type="image/png">
					<img src="../assets/rivals/grafico1.png">
				</picture>
			</div>
		</div>
		<div>
			<div class="registrate-box">
				<h1>REGISTRO</h1>
				<div class="registro-form" style="position: relative;">
					<input v-model="nombre_input" class="form-control" type="text" name="nombre" placeholder="NOMBRE" required/>
					<input v-model="correo_input" class="form-control" type="email" name="correo" placeholder="CORREO" required/>
					<input v-model="gamertag_input" class="form-control" type="text" name="gamertag" placeholder="GAMERTAG" required/>
					<input v-model="estado_input" class="form-control" type="text" name="estado" placeholder="ESTADO DE RESIDENCIA" required/>
					<select style="cursor: pointer;" id="dinamica_select" class="form-control" name="dinamica" required>
						<option value="Dinamica a participar" disabled selected>TORNEO QUE PARTICIPAS</option>
						<option value="FIFA">FIFA</option>
						<option value="DBFZ">DBFZ</option>
						<option value="Fall Guys">FALL GUYS</option>
						<option value="Free Play">FREE PLAY</option>
					</select>
					<div class="terminosChbox" style="position: absolute; width: 70%; display: flex; justify-content: right; align-items: center;">
						<input style="margin: 20px; padding-top: 60px" type="checkbox" id="aceptarAviso" required />
						<span>Acepto términos y condiciones y políticas de privacidad.</span>
					</div>
					
					<div id="alertDiv"></div>
					<button id="registro_btn" @click="register" class="registro_btn">ENVÍAR</button>
				</div>
			</div>
		</div>
		<div>
			<div class="bocas">
				<picture>
					<source srcset="../assets/rivals/bocas_juntas.webp" type="image/webp">
					<source srcset="../assets/rivals/bocas_juntas.png" type="image/png">
					<img src="../assets/rivals/bocas_juntas.png" style="width: 25vw">
				</picture>
			</div>
		</div>
	</div>

	</template>

	<script>
		import axios from 'axios';
		import ConfettiGenerator from "confetti-js";

		export default {
			props: {
				
			},
			components: {
			},
			data() {
				return {
					twitch_host: "https://player.twitch.tv/?channel=trident_mex&parent=rivalstrident.com&muted=false",
					nombre_input: '',
					gamertag_input: '',
					correo_input: '',
					estado_input: '',
					juegos: {
						"cod": "https://battlefy.com/rivals-2022/cod-mobile-rivals/6340897b1bbae511e37458d0/info?infoTab=details",
						"fortnite": "https://battlefy.com/rivals-2022/fortnite-rivals/63408b3976d98e021cbaab74/info?infoTab=details",
						"fallguys": "https://play.toornament.com/es/tournaments/6113185219001098240/"
					},
					talentos: [
					{
						"nombre": "MAFER ROCHA",
						"redes": {
							"fb": "https://www.facebook.com/MaferRochaoficial",
							"ig": "https://www.instagram.com/maferrocha/",
							"tt": "https://twitter.com/MaffyRocha"
						},
						"images": {
							"normal": "./talentos/mafer",
							"redes": "./talentos/mafer_redes"
						}
					},
					{
						"nombre": "MKLEO",
						"redes": {
							"fb": "https://www.facebook.com/FOX.MKLeo",
							"ig": "https://www.instagram.com/mkleosb/",
							"tt": "https://twitter.com/Mkleosb"
						},
						"images": {
							"normal": "./talentos/leo",
							"redes": "./talentos/leo_redes"
						}
					},
					{
						"nombre": "GODDESSALFA",
						"redes": {
							"fb": "https://www.facebook.com/GoddessAlfa2",
							"ig": "https://www.instagram.com/goddessalfa/",
							"tt": "https://twitter.com/GoddessAlfa"
						},
						"images": {
							"normal": "./talentos/godd",
							"redes": "./talentos/godd_redes"
						}
					},
					{
						"nombre": "MYM TUM TUM",
						"redes": {
							"fb": "https://www.facebook.com/fluffytumtum",
							"ig": "https://www.instagram.com/mymtumtum/",
							"tt": "https://twitter.com/mymtumtum"
						},
						"images": {
							"normal": "./talentos/tumtum",
							"redes": "./talentos/tumtum_redes"
						}
					}
					]
				}
			},
			computed: {

			},
			mounted() {
				if(window.location.origin.split('.').length == 2) {
					this.twitch_host = "https://player.twitch.tv/?channel=trident_mex&parent=rivalstrident.com&muted=false";
				} else {
					this.twitch_host = "https://player.twitch.tv/?channel=trident_mex&parent=rivalstrident.com.mx&muted=false";
				}
			},
			methods: {
				showConfetti(){
					var confettiElement = document.getElementById('my-canvas');
					var confettiSettings = {
						target: confettiElement,
						respawn: false,
						start_from_edge: false,
						"props": [
							"circle",
							"square",
							{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_1.png", "size": 60 },
							{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_2.png", "size": 60 },
							{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_3.png", "size": 60 },
							{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_4.png", "size": 60 },
							{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_5.png", "size": 60 }
						]
					};
					var confetti = new ConfettiGenerator(confettiSettings);
					confetti.render();
				},
				goto(to) {
					let to_div = document.getElementById(to);
					to_div.scrollIntoView();
				},

				showAlert(type, msg) {
					document.getElementById('alertDiv').innerHTML = `
					<div class="alert alert-${type} alert-dismissible fade show" role="alert">
					${msg}
					<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
					</div>
					`;
				},

				cleanForm() {
					let registro_btn = document.getElementById("registro_btn");
					let dinamica_select = document.getElementById("dinamica_select");
					registro_btn.removeAttribute('disabled');
					registro_btn.style.backgroundColor = "#ff26d7";
					registro_btn.innerHTML = 'ENVÍAR';
					this.nombre_input = '';
					this.gamertag_input = '';
					this.estado_input = '';
					this.correo_input = '';
					dinamica_select.selectedIndex = 0;
				},

				register(){
					let fd = new FormData();
					let dinamica_select = document.getElementById("dinamica_select");
					fd.append('nombre', this.nombre_input);
					fd.append('gamertag', this.gamertag_input);
					fd.append('correo', this.correo_input);
					fd.append('estado', this.estado_input);
					fd.append('juego', dinamica_select.value);
					let aceptarAviso = document.getElementById("aceptarAviso").checked;

					if(!aceptarAviso) {
						this.showAlert('danger', 'Debes aceptar los términos y condiciones y políticas de privacidad.');
						return;
					}

					let self = this;
					if(dinamica_select.selectedIndex != 0 && this.nombre_input && this.correo_input && this.estado_input && this.gamertag_input) {
						let registro_btn = document.getElementById("registro_btn");
						registro_btn.setAttribute('disabled', true);
						registro_btn.style.backgroundColor = "#969696";
						registro_btn.innerHTML = 'Enviando...';

						axios.post('https://monstar.io/api/gsheets/rivals-registers_2-2022.php', fd, {
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(function (response) {
						//console.log(response);
						if(response.data.message !== "success")
						{
							self.showAlert('danger', 'Error en el registro, intentalo mas tarde.');							
							console.log(response.data.error);
						} else {
							//self.showAlert('success', 'Gracias por registrarte!');
							//self.showRegisterSuccessPopup();
							localStorage.setItem('dinamica_selected', dinamica_select.value);
							window.location.href = "/registro-exitoso";
						}

						self.cleanForm();

					})
						.catch(function (error) {
							console.log(error);
							self.showAlert('danger', 'Error en el registro, intentalo mas tarde.');
							self.cleanForm();
						});
					}else{
						this.showAlert('danger', 'Debes llenar todos los datos.');
					}
				},
			}
		}
	</script>

	<style scoped>
		.video-final {
			width: 60vw;
			margin: 0 auto;
		}

		#my-canvas {
			position: fixed;
			z-index: 50;
			left: 0;
			top: 0;
			user-select: none;
			pointer-events: none;
		}

		.transmision-buttons a, .transmision-buttons button {
			display: inline-block;
			font-family: Montserrat;
			text-decoration: none;
			font-size: 1rem;
			padding: 10px 20px;
			background-color: white;
			outline: none;
			border: 3px solid black;
			color: black;
			margin: 20px;
			box-sizing: border-box;
		}

		.transmision-buttons a:hover, .transmision-buttons button:hover {
			box-shadow: 0 0 20px var(--pink);
		}

		.modal-header, .modal-footer {
			background-image: url('../assets/rivals/arriba_mensaje.png');
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 63px;
		}

		.modal-title {
			width: 100%;
		}

		.modal-body {
			font-family: "Montserrat";
			font-size: 1.2rem;
			padding: 3vw 6vw;
			color: black;
		}

		#modal_link {
			margin: 40px;
			word-break: break-all;
		}

		@keyframes scaled {
			from {transform: scale(1.0);}
			to {transform: scale(1.1);}
		}

		.premios-wrapper .paso:hover, .challenge-box .paso:hover, .talento:hover {
			animation-name: scaled;
			animation-duration: 0.5s;
			transform: scale(1.1);
		}

		.registro-home-btn {
			margin-bottom: -30vw;
		}

		.registro-funciona-btn {
			margin-top: -8vw;
		}

		.transmition-box {
			padding: 0px 15%;
		}

		.eliminatoria-box {
			width: 400px;
			height: auto;
			background-color: #3232ff;
			margin: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.eli-grupo {
			padding: 5% 30%;
			display: flex;
			justify-content: space-around;
			align-items: center;
			font-size: 1.2rem;
		}

		.eli-grupo img {
			height: 3vh;
		}

		.eli-ronda {
			background-color: white;
			color: #000096;
			padding: 10px 0px;
		}

		.eli-tabla table {
			width: 100%;
		}

		.eli-tabla tbody td {
			border-bottom: 2px solid #000096;
			background-color: white;
			color: #000096;
		}

		.eli-tabla tr {
			height: 5vh;
		}

		.eli-twitch-link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: white;
			color: #000096;
			font-size: 1.7rem;
			padding: 10px 40px;
			min-height: 60px;
		}

		.eli-twitch-link img {
			height: 3.3vh;
		}

		.registrate-box {
			background-image: url(../assets/rivals/formulario_bg.png);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: top;
			padding: 50px 40px 0px 40px;
			position: relative;
			z-index: 2;
		}

		.registrate-wrapper.section {
			display: flex;
		}

		.registrate-wrapper .bocas {
			display: flex;
			justify-content: flex-end;
			margin-top: 280px;
			margin-right: 7vw;
		}

		.registro-form {
			max-width: 624px;
			padding: 50px;
			margin: 0 auto;
		}



		.registro-form input, .registro-form select {
			padding: 18px 110px;
			border-radius: 0;
			border: none;
			background-color: white;
			border-bottom: 2px solid #bbb;
			margin: 12px 0px;
			text-align: center;
			color: black;
			font-size: 15px;
		}

		.registro-form select {
			padding:  18px 0px;
		}

		.registro-form button {
			margin: 120px 0px;
			background-color: #ff26d7;
			border: none;
			color: white;
		}

		.section {
			padding-top: 60px;
		}

		.talento {
			max-width: 450px;
			margin: 10px;
		}

		.talento h2 {
			font-family: Steelfish;
			font-size: 3rem;
		}

		.talento img {
			width: 100%;
			margin-bottom: 45px;
		}

		.talento.margin-up {
			padding-top: 200px;
		}

		.talento-redes {
			opacity: 0;
		}

		.talento-redes:hover {
			opacity: 1;
		}

		.talento-redes-links {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: absolute;
			width: 100%;
			height: 100%;
			margin-top: -20px;
			font-size: 3rem;
		}

		.talento-redes-links a {
			color: black;
			z-index: 3;
		}

		@keyframes to-opacity-1 {
			from { opacity: 0; }
			to { opacity: 1; }
		}

		.talento-redes:hover {
			animation-name: to-opacity-1;
			animation-duration: 0.5s;
			opacity: 1;
		}

		.home-wrapper {
			background-image: url(../assets/rivals/home.jpg);
			background-repeat: no-repeat;
			background-position: top;
			background-size: 100vw;
			margin-top: -7vw !important;
		}

		.home-content {
			height: 50vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.talentos-wrapper:target, .funciona-wrapper:target,
		.eliminatorias-wrapper:target, .challenge-wrapper:target,
		.premios-wrapper:target, .registrate-wrapper:target  {
			padding-top: 160px;
		}

		.premios-wrapper h2 {
			font-family: Steelfish;
		}

		.para-registrarte, .conoce {
			margin: 0 auto;
			margin-top: 20px;
			padding: 0px 32px;
			background-color: white;
			width: fit-content;
			vertical-align: middle;
			line-height: 60px;
			font-size: 1.6rem;
		}

		.conoce {
			background-color: #f4f4f4;
			width: 50vw;
			line-height: 2rem;
			font-family: Quicksand, sans-serif;
			font-weight: 400;
		}

		.tres-pasos, .challenge-box, .talentos-box {
			display: flex;
			justify-content: space-around;
			font-size: 1.4rem;
		}

		.registrate-wrapper {
			font-family: Montserrat;
			color: black;
		}

		input::placeholder, input::-ms-input-placeholder  {
			color: black !important;
		}

		.paso {
			max-width: 570px;
			max-height: 570px;
			display: flex;
			margin: 20px;
			flex-direction: column;
			justify-content: space-between;
		}

		.tres-pasos .paso {
			align-items: center;
		}

		.tres-pasos .paso .description {
			margin: 0px;
			font-family: Montserrat;
			font-size: 1.5vw;
			color: black;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			width: 26vw;
			height: 26vw;
			padding: 5vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			background-size: cover;
		}

		.dsc-1 {
			background-image: url(../assets/rivals/cuadro1.png);
		}

		.dsc-2 {
			background-image: url(../assets/rivals/cuadro2.png);
		}

		.dsc-3 {
			background-image: url(../assets/rivals/cuadro3.png);
		}

		.paso img, .paso picture {
			width: 100%;
		}

		.challenge-box .paso {
			justify-content: space-evenly;
		}

		.challenge-box .paso img {
			max-width: 500px !important;
		}

		button {
			font-size: 1.8rem;
		}

		.registro_btn {
			display: inline-block;
			font-family: Montserrat;
			text-decoration: none;
			font-size: 2rem;
			padding: 10px 20px;
			background-color: white;
			outline: none;
			border: 3px solid black;
			color: black;
			margin: 20px;
			box-sizing: border-box;
		}

		.registro_btn:hover {
			box-shadow: 0 0 20px var(--pink);
		}

		@media only screen and (max-width: 1400px) {
			.registrate-wrapper.section {
				flex-direction: column;
			}

			.registrate-left {
				max-width: 100% !important;
			}

			.registrate-left h2 {
				text-align: center !important;
			}

			.registrate-left-image {
				margin: 0 auto;
				margin-top: 20px;
			}
		}

		@media only screen and (max-width: 1300px) {
			.registro-funciona-btn {
				margin-top: -1vw;
			}
		}

		@media only screen and (max-width: 1024px) {
			.talentos-box {
				flex-direction: column;
				margin: 0 auto;
				align-items: center;
			}

			.talento.margin-up {
				padding-top: 0px;
			}

			.registrate-wrapper .bocas {
				display: none;
			}
		}

		@media only screen and (max-width: 768px) {

			.transmition-box {
				padding: 0px;
			}

			.video-final {
				width: 95vw;
			}

			.transmition-box.section {
				margin-top: 10vw;
			}

			.tres-pasos, .challenge-box {
				flex-direction: column;
				margin: 0 auto;
				align-items: center;
			}

			.talento {
				margin-bottom: 80px;
			}

			.talento img {
				margin-bottom: 20px;
			}



			.paso {
				text-align: -webkit-center;
			}

			.tres-pasos .paso .description {
				width: 60vw;
				height: 60vw;
				padding: 12vw;
				font-size: 3vw;
			}


			.conoce {
				width: 80vw;
			}

			.talentos-wrapper:target, .funciona-wrapper:target,
			.eliminatorias-wrapper:target, .challenge-wrapper:target,
			.premios-wrapper:target, .registrate-wrapper:target  {
				padding-top: 0vh;
			}

			.registro_btn {
				font-size: 1.5rem;
			}
			h1 {
				font-size: 7vw;
			}

			h2 {
				font-size: 5vw;
			}

			.conoce {
				width: 100vw;
			}

			.home-content {
				align-items: center;
			}

			.registro_btn {
				font-size: 1rem;
			}

			.paso {
				width: 60vw;
			}

			.home-wrapper {
				background-size: 200vw;
				height: 50vh;
			}

			.registro-home-btn {
				margin-bottom: -100vw
			}

			.registro-funciona-btn {
				margin-top: 10vw;
			}



			.registrate-box {
				padding: 10vw 0vw 0px 0vw;
			}

			.registro-form {
				width: 90vw;
				height: 100vw;
				padding-top: 7vw;
			}

			.registro-form input, .registro-form select {
				padding: 2vw 8vw;
				border-radius: 0;
				background-color: white;
				border-bottom: 2px solid #bbb;
				margin: 2vw 0px;
				text-align: center;
				color: black;
				font-size: 4vw;
			}

			.registro-form .terminosChbox {
				margin-top: 8vh;

			}

			.registrate-box h1 {
				font-size: 6vw;
			}

			.registro-form select {
				padding:  18px 0px;
			}

			.registro-form button {
				margin: 0px 0px 0px 0px;
				font-size: 4vw;
			}
		}
	</style>