<template>
	<header id="header" style="position: sticky; width: 100%; top: 0; z-index: 10">
		<nav class="navbar navbar-expand-lg" style="padding: 1rem !important; background-color: #ff25d7;">
			<div style="display: flex; justify-content: center; align-items: center; width: 100%; padding: 0px 10px;">
				<div style="display: flex; justify-content: center; align-items: center;">
					<router-link @click="navCollapse" id="homelogo_hl" class="navbar-brand" to="/" style="min-width: 50px; max-width: 150px; margin-right: 20px;">
						<picture>
							<source srcset="../assets/rivals/logo_rivals.webp" type="image/webp">
							<source srcset="../assets/rivals/logo_rivals.png" type="image/png">
							<img style="width: 100%" src="../assets/rivals/logo_rivals.png">
						</picture>
						
					</router-link>					
				</div>
				<div style="display: flex; flex-direction: column;">
					<div class="redes">
						<a href="https://www.facebook.com/RivalsMexico" target="_blank"><i class="fab fa-facebook-square"></i></a>
						<a href="https://twitter.com/Trident_mx" target="_blank"><i class="fab fa-twitter-square"></i></a>
						<a href="https://www.instagram.com/rivals_mexico/" target="_blank"><i class="fab fa-instagram"></i></a>
						<a href="https://discord.gg/55aVZnqGZ4" target="_blank"><i class="fab fa-discord"></i></a>
						<!--<a href="#" target="_blank"><i class="fab fa-twitch"></i></a>-->
					</div>
					<div class="collapse navbar-collapse" id="navbarNav">
						<ul class="navbar-nav ml-auto">
							<li class="nav-item nav-btn">
								<router-link @click="navCollapse" id="home_hl" class="nav-link" to="/">HOME</router-link>
							</li>
							<li class="nav-item nav-btn">
								<router-link  @click="navCollapse" id="hall_of_fame_hl" class="nav-link" to="/hall_of_fame">HALL OF FAME</router-link>
							</li>
							<li class="nav-item nav-btn">
								<router-link class="nav-link" id="transmision_hl" @click="navCollapse" to="/transmision">TRANSMISIÓN</router-link>
							</li>
							<!--<li class="nav-item nav-btn">
								<router-link class="nav-link" id="juegos_hl" @click="navCollapse" to="/juegos">JUEGOS</router-link>
							</li>
							<li class="nav-item nav-btn">
								<router-link  @click="navCollapse" id="funciona_hl" class="nav-link" to="/funciona">¿CÓMO FUNCIONA?</router-link >
							</li>-->
							<li class="nav-item nav-btn">
								<router-link  @click="navCollapse" id="talento_hl" class="nav-link" to="/talento">TALENTO</router-link >
							</li>
							<li class="nav-item nav-btn">
								<router-link  @click="navCollapse" id="premios_hl" class="nav-link" to="/premios">PREMIOS</router-link >
							</li>
							<li class="nav-item nav-btn">
								<router-link  @click="navCollapse" id="registro_hl" class="nav-link" to="/registro">REGISTRO</router-link >
							</li>
							
						</ul>
					</div>
				</div>
				<button id="navToggler" class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<i class="fas fa-bars" style="color:#fff; font-size:22px;"></i>
				</button>
			</div>
		</nav>
	</header>
	<div style="display: none">
		<a data-toggle="modal" data-target="#stream_modal" id="stream_modal_btn"></a>
	</div>
</template>

<script>
	//import { ref } from 'vue';

	export default {
		name: 'Header',
		props: {
			route: String,
			
		},
		data() {
			return {
				last_link: "",
			}
		},
		mounted() {
			
			let to_parts = window.location.href.split('/');
			var to = 'home';

			//document.getElementById(to + "_hl").classList.remove("active");

			if(to_parts.length == 4) {
				to = to_parts[3];
			}

			this.last_link = document.getElementById(to + "_hl");

			//document.getElementById(to + "_hl").classList.add("active");

			setTimeout(()=>{
				this.goto(to);
			}, 250);
		},
		methods: {
			goto(to) {
				let to_div = document.getElementById(to);
				to_div.scrollIntoView();
				//this.last_link.classList.remove("active");
				var link = document.getElementById(to + "_hl");
				link.classList.add("active");
			},
			navCollapse(e) {
				//e.target.click();
				console.log(e.target.id);
				var to = "";
				if(e.target.id == "homelogo_hl") {
					to = "home";
				} else {
					to = e.target.id.split('_hl')[0];
				}

				//this.last_link.classList.remove("active");
				//e.target.classList.toggle("active");
				this.last_link = e.target;
				this.goto(to);
				if(window.innerWidth < 991) {
					document.getElementById("navToggler").click();
				}
			}
		}
	}
</script>

<style scoped>

	.navbar {
		padding: 0px !important;
		box-sizing: border-box;
		border-bottom: 6px solid black;
	}

	#navbarNav {
		justify-content: right;
		max-width: 1000px;

	}

	.navbar-nav a {
		font-family: Montserrat;
		font-size: 1.2rem;
		font-weight: 800;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		color: black;
		margin: 0px 10px;
		box-sizing: border-box;
		text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
	}

	.navbar-nav a:hover, .nav-link.active {
		border: 3px solid white;
		box-shadow: 0 0 20px #00ff5f;
	}

	.hide {
		display: none;
	}

	.modal-xxl {
		max-width: 100%;
	}

	.selected {
		border-bottom: 3px solid var(--yellow);
	}

	.container-fluid {
		width: 70%;
		margin: 0 auto;
	}

	.redes {
		display: flex;
		font-size: 1.6rem;
		justify-content: right;
	}

	.redes i {
		color: white;
		margin: 0px 10px;
		cursor: pointer;
	}

	.banderas {
		display: flex;
		justify-content: right;
		align-items: flex-end;
	}

	.banderas div {
		margin: 0px 10px;
		min-width: 44px;
		max-width: 6%;
		cursor: pointer;
	}

	.banderas img {
		width: 100%;
	}

	@media only screen and (max-width: 991px) {
		.redes {
			justify-content: center;
		}
	}

</style>