import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import RegistroExitoso from '../views/RegistroExitoso.vue'
import AvisoPrivacidad from '../views/AvisoPrivacidad.vue'
import TerminosCondiciones from '../views/TerminosCondiciones.vue'
import TransmisionEspecial from '../views/TransmisionEspecial.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/juegos',
    name: 'Juegos',
    component: Home
  },
  {
    path: '/premios',
    name: 'Premios',
    component: Home
  },
  {
    path: '/transmision',
    name: 'Transmision',
    component: Home
  },
  {
    path: '/transmision-especial',
    name: 'TransmisionEspecial',
    component: TransmisionEspecial
  },
  {
    path: '/funciona',
    name: 'Funciona',
    component: Home
  },
  {
    path: '/registro',
    name: 'Registro',
    component: Home
  },
  {
    path: '/talento',
    name: 'Talento',
    component: Home
  },
  {
    path: '/hall_of_fame',
    name: 'HallOfFame',
    component: Home
  },
  {
    path: '/registro-exitoso',
    name: 'RegistroExitoso',
    component: RegistroExitoso
  },
  {
    path: '/avisodeprivacidad',
    name: 'AvisoPrivacidad',
    component: AvisoPrivacidad
  },
  {
    path: '/terminosycondiciones',
    name: 'TerminosCondiciones',
    component: TerminosCondiciones
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
