<template>
<!-- Registro exitoso Modal -->
<!-- Button trigger modal -->
<button id="registro_popup_btn" style="display: none" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#registroModal">
</button>
<div class="modal fade" id="registroModal" tabindex="-1" aria-labelledby="resitroModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title fs-5" id="resitroModalLabel">!REGISTRO EXITOSO!</h1>
				<button type="button" @click="gotoHome" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>Gracias por registrarte para asistir a la Viewing Party de Trident Rivals 2022, que se llevará a cabo en Arena Artz Pedregal en CDMX el día sábado 17 de diciembre.<br><br>Te enviaremos por correo la invitación a la Viewing Party en caso de ser seleccionado.</p>
			</div>
			<div class="modal-footer"></div>
		</div>
	</div>
</div>
</template>

<script>
	
	export default {
		mounted() {
			this.showRegisterSuccessPopup();
		},
		methods: {
			gotoHome() {
				window.location.href="/";
			},
			showRegisterSuccessPopup() {
				document.getElementById("registro_popup_btn").click();
			}
		}
	}

</script>

<style scoped>
	.modal-header, .modal-footer {
			background-image: url('../assets/rivals/arriba_mensaje.png');
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 63px;
		}

		.modal-title {
			width: 100%;
		}

		.modal-body {
			font-family: "Montserrat";
			font-size: 1.2rem;
			padding: 3vw 6vw;
			color: black;
		}

		#modal_link {
			margin: 40px;
			word-break: break-all;
		}
</style>