<template>
	<div id="body_opacity" class="opacity-0">
		<Header/>
		<router-view/>
		<Footer/>
	</div>
</template>

<style>

	@font-face {
		font-family: "Steelfish";
		src: local("Steelfish"),
		url("./assets/rivals/Steelfish.otf") format("opentype");
	}

	@font-face {
		font-family: "Montserrat";
		src: local("Montserrat-Black"),
		url("./assets/rivals/Montserrat-Black.ttf") format("truetype");
	}

	#body_opacity {
		opacity: 1;
		transition: opacity .25s;
	}

	.opacity-0 {
		opacity: 0;
	}

	.opacity-1 {
		opacity: 1;
	}

	body {
		background-image: url(./assets/rivals/fondo_verde.jpg);
		background-repeat: repeat;
		box-sizing: border-box;
	}

	.container-fluid {
		width: 90vw !important;
		margin: 0 auto;
	}

	:root {
		--pink: #ff25d7;
	}
	.title h1, .title h2, .title h3{
		text-transform: uppercase;
	}

	h1 {
		font-size: 2.5rem;
	}

	h2 {
		font-size: 2rem;
	}

	h3 {
		font-size: 1rem;
	}

	h1, h2, h3 {
		font-family: Montserrat;
		font-weight: 800;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		color: black;
		margin: 0px 10px;
		box-sizing: border-box;
		text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
	}

	html {
		scroll-behavior: smooth;
	}

	#app {
		font-family: Steelfish, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: white;
	}

	#nav a.router-link-exact-active {
		color: #42b983;
	}

</style>

<script>
	import "bootstrap/dist/css/bootstrap.min.css";
	import Header from  './components/Header';
	import Footer from  './components/Footer';

	export default {
		name: 'App',
		components: {
			Header,
			Footer
		},
		data() {
			return {
				
			}
		},
		beforeMount() {
			setTimeout(() => {
				document.getElementById("body_opacity").classList.toggle("opacity-0");
			}, 120);
		},
		mounted() {
			
		},
		created() {
			
		}
	}
</script>
