<template>
<!-- Registro exitoso Modal -->
<!-- Button trigger modal -->
<button id="registro_popup_btn" style="display: none" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#terminosModal">
</button>
<div class="modal fade" id="terminosModal" tabindex="-1" aria-labelledby="terminosModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title fs-5" id="terminosModalLabel">T&Eacute;RMINOS Y CONDICIONES</h1>
				<button type="button" @click="gotoHome" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p><strong>T&Eacute;RMINOS Y CONDICIONES PARA &ldquo;EL TORNEO&rdquo; &ldquo;RIVALS 2022&rdquo; (las &ldquo;Bases&rdquo;).</strong></p><br>

<p>I. BASES LEGALES.<br />
Toda persona que conforme a las presentes bases, que desee participar en &ldquo;EL TORNEO&rdquo;<br />
&ldquo;RIVALS 2022&rdquo; (en lo sucesivo, &ldquo;EL TORNEO&rdquo;), acepta cumplir con la totalidad de ellas y al<br />
participar se entender&aacute; que ha le&iacute;do y comprendido &iacute;ntegramente estas bases y se<br />
compromete a cumplir totalmente con los requisitos y condiciones aqu&iacute; establecidos (en lo<br />
sucesivo, las &ldquo;Bases&rdquo;) los cuales se encontrar&aacute;n el sitio web con direcci&oacute;n en<br />
https://rivalstrident.com (en lo sucesivo el &ldquo;Sitio Rivals&rdquo;).<br />
Patrocinador: Mondelez M&eacute;xico S. de R.L. de C.V.<br />
Mondelez M&eacute;xico S. de R.L. de C.V. (en adelante, como el &ldquo;Patrocinador&rdquo;), patrocinador del<br />
presente torneo de habilidad y destreza objeto de las presentes bases del cual se obtendr&aacute;n<br />
a los ganadores de &ldquo;EL TORNEO&rdquo; &ldquo;RIVALS 2022&rdquo;, en este puede participar el p&uacute;blico en<br />
general, siempre que &ldquo;EL JUGADOR&rdquo; cumpla con los requisitos que se se&ntilde;alan en las<br />
presentes &ldquo;Bases&rdquo;.<br />
Organizador de &ldquo;EL TORNEO&rdquo;: GAMING TRAINERS SA DE CV.<br />
GAMING TRAINERS S.A.P.I. DE C.V. (en adelante, indistintamente como &ldquo;EL<br />
ORGANIZADOR&rdquo;) quien es &ldquo;EL ORGANIZADOR&rdquo; del presente torneo de habilidad y<br />
destreza objeto de las presentes bases de &ldquo;EL TORNEO&rdquo;. Del cual se generar&aacute;n los<br />
ganadores de &ldquo;EL TORNEO&rdquo; &ldquo;RIVALS 2022&rdquo;, y a quienes se les entregar&aacute;n los premios<br />
se&ntilde;alados en las presentes bases. Puede participar el p&uacute;blico en general, siempre que &ldquo;EL<br />
JUGADOR&rdquo; cuente con los siguientes requisitos:<br />
II. LOS JUGADORES.<br />
El p&uacute;blico consumidor que re&uacute;na lo siguientes requisitos ser&aacute; considerado como &ldquo;EL<br />
JUGADOR&rdquo; y deber&aacute; tomar&aacute; en cuenta lo siguiente:<br />
1. Ser residentes legales en los Estados Unidos Mexicanos, toda persona de cualquier<br />
nacionalidad puede participar, siempre y cuando resida dentro del Territorio.<br />
2. Podr&aacute;n inscribirse y participar las personas mayores de 14 a&ntilde;os cumplidos antes de la<br />
fecha de inicio de &ldquo;EL TORNEO&rdquo;, para lo cual deber&aacute;n contar con la autorizaci&oacute;n del padre<br />
o tutor &uacute;nicamente para los videojuegos COD Mobile, Fortnite y Fall Guys.<br />
4. Debe contar con un correo electr&oacute;nico que servir&aacute; como m&eacute;todo de comunicaci&oacute;n entre<br />
&ldquo;EL ORGANIZADOR&rdquo; y &ldquo;EL JUGADOR&rdquo;.<br />
5. &ldquo;EL JUGADOR&rdquo; deber&aacute; contar con el videojuego requerido por &ldquo;EL TORNEO&rdquo; en la<br />
plataforma determinada para cada torneo y una conexi&oacute;n de internet con una velocidad<br />
m&iacute;nima de 10-15 Mbps, el reglamento los cuales se encuentran en el sitio web con dominio<br />
en www.rivalstrident.com<br />
Especificaciones t&eacute;cnicas para cada juego:</p>

<p>COD (Mobile):<br />
Sistema operativo: Android o iOS.<br />
CPU: Todos los procesadores recientes.<br />
RAM: 2 GB como m&iacute;nimo, 4 GB recomendados.<br />
Almacenamiento: 3 GB de espacio libre m&iacute;nimo.<br />
Actualice el controlador de la tarjeta gr&aacute;fica.<br />
Utilice la cuenta de administrador de su PC.<br />
Fortnite:<br />
Contar con el juego Fortnite para cualquier plataforma de videojuegos.<br />
Contar con la versi&oacute;n m&aacute;s actualizada del juego.<br />
Contar con una conexi&oacute;n de 10 a 15 Mbps de internet.<br />
En caso de pedir alg&uacute;n tipo de licencia par jugar online contar con ella<br />
Fall Guys:<br />
Contar con una computadora, laptop, con los siguientes requisitos:<br />
Requiere un procesador y un sistema operativo de 64 bits.<br />
SO: Windows 10 64 bit only.<br />
Procesador: Intel Core i5 or AMD equivalent.<br />
Memoria: 8 GB de RAM.<br />
Gr&aacute;ficos: NVIDIA GTX 660 or AMD Radeon HD 7950.<br />
Red: Conexi&oacute;n de banda ancha a Internet.<br />
Contar con la versi&oacute;n m&aacute;s actualizada del juego<br />
Contar con una conexi&oacute;n de 10 a 15 Mbps de internet<br />
6. Antes, durante o al finalizar &ldquo;EL TORNEO&rdquo; se solicitar&aacute; cualquiera de los siguientes<br />
documentos:<br />
a) Una identificaci&oacute;n oficial para comprobar que &ldquo;EL JUGADOR&rdquo; cuenta con la edad<br />
correspondiente y necesaria para jugar. En caso de no poder acreditar la edad solicitada, el<br />
jugador ser&aacute; descalificado de &ldquo;EL TORNEO&rdquo;. Cuando &ldquo;EL JUGADOR&rdquo; sea menor de edad<br />
se requerir&aacute; la autorizaci&oacute;n por escrito de los padres o tutores del mismo, quienes a su vez<br />
deber&aacute;n acreditar su personalidad y deber&aacute;n proporcionar una identificaci&oacute;n oficial.<br />
b) Documento migratorio que permita comprobar la legal estancia en el Territorio.<br />
c) Acta de nacimiento en el caso de menores de edad.<br />
No podr&aacute;n participar las personas que sean familiares hasta el tercer grado en l&iacute;nea<br />
ascendente o descendente de un empleado o empleados tanto de &ldquo;EL ORGANIZADOR&rdquo;,<br />
as&iacute; como de cualquiera de las empresas filiales, controladas o bajo el control de &ldquo;EL<br />
ORGANIZADOR&rdquo; y &ldquo;El PATROCINADOR&rdquo;.<br />
Solo las personas que cumplan con la totalidad de los requisitos anteriormente descritos<br />
podr&aacute;n ser consideradas como &ldquo;EL JUGADOR&rdquo; en &ldquo;EL TORNEO&rdquo;.<br />
III. VIGENCIA &ldquo;EL TORNEO&rdquo;.</p>

<p>El periodo de vigencia de &ldquo;EL TORNEO&rdquo; inicia a las 18:00 horas del d&iacute;a 12 de noviembre<br />
del 2022 y concluir&aacute; a las 23:59 horas del d&iacute;a 17 de diciembre del 2022 (en lo sucesivo, la<br />
&ldquo;Vigencia&rdquo;).<br />
IV. TERRITORIO.<br />
El comprendido por los Estados Unidos Mexicanos.<br />
V. MEC&Aacute;NICA &ldquo;EL TORNEO&rdquo;.<br />
El desarrollo de &ldquo;EL TORNEO&rdquo; se llevar&aacute; a cabo de conformidad con lo previsto en el<br />
reglamento general de competencia y reglamentos individuales, todos comprendidos en el<br />
Sitio con dominio en www.rivalstrident.com<br />
Reglamento General.<br />
La competencia se desarrolla en los siguientes formatos por cada torneo:<br />
Call Of Duty Mobile: 4vs4 (equipos de cuatro jugadores).<br />
Fortnite: 2v2 (equipos de dos jugadores).<br />
Fall Guys: torneo individual<br />
El torneo suceder&aacute; en las siguientes fechas:<br />
Registros: del 12 de noviembre al 6 de diciembre.<br />
Enfrentamientos: del 7 al 12 de diciembre.<br />
Gran final: 17 de diciembre.<br />
Previo a la transmisi&oacute;n.<br />
&ldquo;El organizador&rdquo; determinar&aacute; las fechas y los horarios de las partidas que ser&aacute;n<br />
transmitidas; esta informaci&oacute;n ser&aacute; anunciada por los canales oficiales y en privado a los<br />
jugadores.<br />
Los jugadores est&aacute;n obligados a confirmar y presentarse a la transmisi&oacute;n correspondiente<br />
con un tiempo de anticipaci&oacute;n m&iacute;nimo de 30 (treinta) minutos al comienzo de su partida.<br />
El organizador podr&aacute; solicitar datos personales de contacto del jugador con el objetivo de<br />
mantener comunicaci&oacute;n en todo momento. Esta informaci&oacute;n puede ser correo, tel&eacute;fono<br />
personal o WhatsApp. La solicitud de datos personales se sujetar&aacute; a lo establecido en el<br />
Aviso de Privacidad que forma parte de este T&eacute;rminos y Condiciones.<br />
El jugador que incumpla con el reglamento de transmisi&oacute;n y no se presente dentro del canal<br />
de Discord o v&iacute;a WhatsApp, ser&aacute; descalificado y sustituido por el jugador anterior inmediato<br />
del bracket.<br />
El jugador que no pueda presentarse a la transmisi&oacute;n por motivos ajenos a la competencia<br />
deber&aacute; informar al organizador con un m&iacute;nimo de 4 (cuatro) d&iacute;as naturales de anticipaci&oacute;n,<br />
de no ser as&iacute; se considerar&aacute; que el jugador est&aacute; disponible para jugar sin excepci&oacute;n, en<br />
caso de que no est&eacute; disponible el jugador el organizador podr&aacute; reemplazar al jugador en<br />
turno para los eventos de transmisi&oacute;n, sin previo aviso.<br />
En el caso donde se requiera una entrega de material grabado para realizar la transmisi&oacute;n,<br />
el jugador est&aacute; obligado a la entrega de este. En caso de incumplir con la entrega despu&eacute;s<br />
de su encuentro ser&aacute; descalificado y sustituido por el jugador anterior inmediato del bracket.<br />
Durante la transmisi&oacute;n.</p>

<p>En algunas transmisiones ser&aacute; necesario que los jugadores compartan su c&aacute;mara mientras<br />
juegan. Ser&aacute; responsabilidad del jugador perfilar su c&aacute;mara de tal manera que esta pueda<br />
grabar su rostro, si incumple con este requisito el jugador no ser&aacute; transmitido en la<br />
transmisi&oacute;n y podr&aacute; ser sancionado en su puntaje.<br />
El jugador tiene prohibido hacer se&ntilde;ales con trasfondo altisonante, o que vaya en contra de<br />
la marca patrocinante, religioso o pol&iacute;tico hacia la c&aacute;mara, en caso de incurrir en estas falta<br />
podr&aacute; ser penalizado con el veto de toda la competencia.<br />
El jugador tiene prohibido contar con terceras personas durante la transmisi&oacute;n, en caso de<br />
incurrir en esta falta ser&aacute; considerado como trampa y podr&aacute; ser penalizado con el veto de<br />
toda la competencia.<br />
VI. PREMIOS.<br />
Fortnite<br />
1er Lugar (2 jugadores): Xbox Series X y Headset Logitech G 733.<br />
2do Lugar (2 jugadores): Silla Gamer y Headset Logitech G 733.<br />
3er Lugar (2 jugadores): Silla Gamer.<br />
4to Lugar (2 jugadores): Headset Logitech G 733.<br />
COD Mobile<br />
1er Lugar (4 jugadores): Xiaomi Redmi K40 Gaming Edition Dual SIM 256 GB 12 GB RAM.<br />
2do Lugar (4 jugadores): Silla Gamer.<br />
3er Lugar (4 jugadores): Headset Logitech G 733.<br />
4to Lugar (4 jugadores): Headset ASTRO Gaming A10.<br />
Fall Guys<br />
1er Lugar (1 jugador): Xbox Series X, Silla Gamer y Headset Logitech G733.<br />
2do Lugar (1 jugador): Nintendo Switch y Control Nintendo Switch.<br />
3er Lugar (1 jugador): Silla Gamer y Headset Logitech G 733.<br />
4to Lugar (1 jugador): Headset Logitech G 733.<br />
Premios MVP<br />
3 viajes a San Francisco California que se distribuir&aacute;n de la siguiente manera:<br />
1 jugador de COD Mobile MVP<br />
1 jugador de Fortnite MVP<br />
1 jugador de FallGuys MVP<br />
Los tres jugadores que viajan ser&aacute;n determinados seg&uacute;n las siguientes condiciones:<br />
Contar con los documentos y las condiciones necesarias para realizar un viaje redondo a<br />
vigenEE.UU. en caso de no tener los documentos necesarios se otorgar&aacute; el premio al<br />
segundo MVP y sucesivamente hasta obtener a los ganadores del viaje.<br />
Ser el jugador m&aacute;s valioso del equipo durante las partidas de la gran final. Este rubro estar&aacute;<br />
determinado por los resultados del juego c&oacute;mo se desglosa a continuaci&oacute;n:<br />
Fortnite: Mayor n&uacute;mero de victorias, eliminaciones y da&ntilde;o infligido.<br />
COD Mobile: Puntos otorgados por el juego, mayor n&uacute;mero de eliminaciones, menor<br />
n&uacute;mero de bajas durante la partida y mayor n&uacute;mero de asistencias.<br />
Fall Guys: Ganador de la corona en la partida.<br />
Los viajes se entregar&aacute;n en los primeros 3 meses del a&ntilde;o 2023.</p>

<p>Pertenecer al equipo confirmado como primer lugar del torneo en el que disput&oacute; (Fortnite,<br />
COD Mobile o Fall Guys).<br />
&ldquo;EL ORGANIZADOR&rdquo; se reserva el derecho de asignar el viaje a otro jugador de la<br />
competencia si &ldquo;EL JUGADOR&rdquo; no cumple con una o m&aacute;s de las condiciones antes<br />
mencionadas.<br />
La determinaci&oacute;n sobre la distribuci&oacute;n de premios se realizar&aacute; una vez concluida la Vigencia<br />
de &ldquo;EL TORNEO&rdquo;. Dicha determinaci&oacute;n tendr&aacute; como base los premios previamente<br />
se&ntilde;alados y se har&aacute; en conformidad con la posici&oacute;n final de &ldquo;EL TORNEO&rdquo;. &ldquo;EL<br />
ORGANIZADOR&rdquo; se reserva el derecho de modificaci&oacute;n sobre la determinaci&oacute;n de<br />
distribuci&oacute;n de los premios durante cualquier momento &ldquo;EL TORNEO&rdquo;.<br />
Adem&aacute;s, &ldquo;EL JUGADOR&rdquo; acepta la menci&oacute;n de su nombre y uso de su personalidad en<br />
Activos Digitales Encriptados (NFT) como parte del premio en &ldquo;EL TORNEO&rdquo;, el uso del<br />
mismo podr&iacute;a durar vigente hasta dos a&ntilde;os.<br />
VII. GRAN FINAL.<br />
&ldquo;EL JUGADOR&rdquo; se compromete a cumplir con los horarios y procesos indicados por &ldquo;EL<br />
ORGANIZADOR&rdquo; para realizar en tiempo y forma el viaje redondo, d&iacute;a de prensa, grabaci&oacute;n<br />
de partidas, entrevistas y cualquier otro material que &ldquo;EL ORGANIZADOR&rdquo; crea pertinente.<br />
S&oacute;lo las disciplinas de COD: Mobile y FALL GUYS contar&aacute;n con una gran final presencial,<br />
en el caso de FORTNITE los compromisos de horarios y procesos se limitan al formato en<br />
l&iacute;nea.<br />
Cualquier jugador que no se encuentre en condiciones para viajar y cumplir con los horarios<br />
de la gran final podr&aacute; ser descalificado a discreci&oacute;n de &ldquo;EL ORGANIZADOR&rdquo;.<br />
Las fechas comprometidas se desglosan a continuaci&oacute;n:<br />
Vuelo a CDMX - 16 de diciembre.<br />
D&iacute;a de prensa (media day) - 16 de diciembre.<br />
Enfrentamientos presenciales - 17 de diciembre.<br />
Vuelo de regreso a casa - 18 de diciembre.<br />
*En todos los d&iacute;as se considera horario completo.<br />
VIII. PUBLICACI&Oacute;N DE RESULTADOS Y ENTREGA DE PREMIOS.<br />
La publicaci&oacute;n de los ganadores en el Sitio Rivals se realizar&aacute; 7 (siete) d&iacute;as h&aacute;biles<br />
posteriores a la terminaci&oacute;n de la Vigencia de &ldquo;EL TORNEO&rdquo;. Los premios se enviar&aacute;n por<br />
correspondencia y se realizar&aacute;n en un per&iacute;odo m&aacute;ximo de 60 (sesenta) d&iacute;as h&aacute;biles<br />
despu&eacute;s de dicha publicaci&oacute;n.<br />
Los ganadores ser&aacute;n contactados por medio del correo electr&oacute;nico registrado, para<br />
solicitarles sus datos, tales como: copia simple de una identificaci&oacute;n oficial vigente del<br />
ganador en caso de ser mayor de edad y/o de los padres o tutores en caso de que los<br />
ganadores sean menores de edad (INE, Pasaporte, C&eacute;dula profesional o Cartilla) y su<br />
direcci&oacute;n f&iacute;sica para realizar el env&iacute;o del premio f&iacute;sico al que se haya hecho acreedor para</p>

<p>que por ese medio se pueda coordinar la entrega de los premios, los cuales ser&aacute;n<br />
controlados conforme al AVISO DE PRIVACIDAD de Mondelez M&eacute;xico S. de R.L. de C.V.<br />
Para poder reclamar el premio, los ganadores por su propio derecho en caso de ser<br />
mayores de edad y a trav&eacute;s de sus padres o tutores en caso de ser menores de edad,<br />
seg&uacute;n sea el caso, deber&aacute;n presentar identificaci&oacute;n oficial (INE, Pasaporte) vigente en el<br />
momento que &ldquo;EL ORGANIZADOR&rdquo; lo solicite y en el caso de ser acreedor de un premio en<br />
efectivo, el ganador deber&aacute; expedir una factura por la cantidad a cobrar, correspondiente al<br />
premio expresado en el apartado &ldquo;Premios&rdquo;.<br />
IX. ENTREGA DE LOS PREMIOS.<br />
La entrega de los premios se realizar&aacute; en un lapso no mayor a 60 (sesenta) d&iacute;as h&aacute;biles a<br />
partir de que se publicaron los ganadores &ldquo;DEL TORNEO&rdquo; y que los jugadores hayan<br />
entregado en forma mediante correo electr&oacute;nico los datos solicitados previamente<br />
se&ntilde;alados.<br />
&ldquo;El ORGANIZADOR&rdquo; se encargar&aacute; de entregar los premios de manera presencial, online o<br />
mediante alg&uacute;n servicio de paqueter&iacute;a en caso de que los ganadores no se encuentren<br />
dentro de la CDMX o &aacute;rea metropolitana.<br />
&ldquo;EL ORGANIZADOR&rdquo; se reserva el derecho de no otorgar cualquier premio si tiene<br />
sospechas que los documentos son falsos o no logran acreditar de manera satisfactoria su<br />
identidad, como &ldquo;EL JUGADOR&rdquo; participante.<br />
&ldquo;EL ORGANIZADOR&rdquo; solicitar&aacute; al ganador la firma de un documento y una fotograf&iacute;a que<br />
evidencie la correcta entrega del premio y la consecuente liberaci&oacute;n de responsabilidad.<br />
X. EL SITIO.<br />
&ldquo;EL JUGADOR&rdquo; acepta el uso del sitio bajo su &uacute;nica y exclusiva responsabilidad. &ldquo;EL<br />
ORGANIZADOR&rdquo; no garantiza que &ldquo;EL JUGADOR&rdquo; pueda efectivamente acceder a el sitio<br />
web, ya que este no provee este servicio, siendo esto responsabilidad exclusiva de las<br />
prestadoras de servicios de internet, o de las redes que fueran necesarias a los efectos de<br />
la utilizaci&oacute;n del sitio web.<br />
XI. LEGISLACI&Oacute;N APLICABLE.<br />
Las actividades de &ldquo;EL TORNEO&rdquo; se rigen por la legislaci&oacute;n vigente en la Rep&uacute;blica<br />
Mexicana y por los presentes T&eacute;rminos y Condiciones.<br />
XII. PROPIEDAD INTELECTUAL.<br />
&ldquo;EL ORGANIZADOR&rdquo; es titular de la licencia, derechos, t&iacute;tulos e intereses relacionados a<br />
&ldquo;EL TORNEO&rdquo; &ldquo;RIVALS 2022&rdquo;, incluyendo los relacionados con patente, copyright, secreto<br />
comercial, marcas registradas y todos los dem&aacute;s derechos de propiedad industrial y<br />
derechos de autor.</p>

<p>Epic Games y Activision son los propietarios de los derechos exclusivos de sus respectivos<br />
juegos y de su propiedad intelectual.<br />
Usted no adquiere ning&uacute;n derecho de propiedad sobre ning&uacute;n contenido del &ldquo;EL TORNEO&rdquo;<br />
&ldquo;RIVALS 2022&rdquo; como resultado de su participaci&oacute;n.<br />
Todo el material (datos, im&aacute;genes, videos, rese&ntilde;as, escritos, gr&aacute;ficas, etc.) generado y<br />
publicado en el Sitio Rivals es propiedad de Mondelez M&eacute;xico S. de R.L. de C.V.(&ldquo;EL<br />
PATROCINADOR&rdquo;) quien podr&aacute; utilizarlo y explotarlo comercialmente en cualquier momento<br />
o vigencia y en cualquier medio existente o por existir seg&uacute;n considere conveniente.<br />
XIII. VIOLACI&Oacute;N DE DERECHOS DE AUTOR.<br />
&ldquo;EL ORGANIZADOR&rdquo; as&iacute; como &ldquo;EL JUGADOR&rdquo; debe respetar la propiedad intelectual de<br />
Mondelez M&eacute;xico S. de R.L. de C.V. y de &ldquo;EL TORNEO&rdquo;.<br />
&ldquo;EL JUGADOR&rdquo; al aceptar los presentes T&eacute;rminos y Condiciones, y participar en &ldquo;EL<br />
TORNEO&rdquo;, reconoce y acepta que ser&aacute; el &uacute;nico responsable de verificar y/o en su caso<br />
obtener los permisos, licencias, autorizaciones que conforme a la ley aplicable sean<br />
necesarias respecto al material que &ldquo;EL JUGADOR&rdquo; llegue a usar en relaci&oacute;n con esta o<br />
alguna otra din&aacute;mica &ldquo;EL ORGANIZADOR&rdquo; en la que desee participar. &ldquo;EL JUGADOR&rdquo;<br />
acepta que &ldquo;EL ORGANIZADOR&rdquo; en ning&uacute;n caso ser&aacute; responsable del uso que &ldquo;EL<br />
JUGADOR&rdquo; d&eacute; a dicho material y que nada de lo aqu&iacute; previsto se puede interpretar o<br />
implicar que &ldquo;EL ORGANIZADOR&rdquo; incentiva o promueve el uso de material propiedad de<br />
terceros sin tener las autorizaciones y/o permisos requeridos para tal fin. &ldquo;EL<br />
ORGANIZADOR&rdquo; se reserva el derecho de reclamar y/o de ejercer las acciones legales que<br />
sean aplicables en contra &ldquo;EL JUGADOR&rdquo; por el uso no autorizado o incorrecto que &eacute;ste<br />
haga del material y que lleguen a ser del conocimiento &ldquo;EL ORGANIZADOR&rdquo;. &ldquo;EL<br />
JUGADOR&rdquo; libera de cualquier responsabilidad a &ldquo;EL ORGANIZADOR&rdquo; y a &ldquo;EL<br />
PATROCINADOR&rdquo;, sus empresas relacionadas, sus funcionarios, accionistas,<br />
representantes y empleados de cualquier reclamaci&oacute;n de terceros que surjan en relaci&oacute;n<br />
con el uso que &ldquo;EL JUGADOR&rdquo; haga del material en relaci&oacute;n con &ldquo;EL TORNEO&rdquo; y/o<br />
din&aacute;mica o cualesquiera otras &ldquo;EL ORGANIZADOR&rdquo; en las que &ldquo;EL JUGADOR&rdquo; participe.<br />
&ldquo;EL JUGADOR&rdquo;, declara que autoriza el uso de las fotograf&iacute;as, im&aacute;genes y/u obras que<br />
puedan obtenerse derivado de su participaci&oacute;n en &ldquo;EL TORNEO&rdquo; para fines promocionales,<br />
durante y hasta por 48 meses despu&eacute;s de finalizado &ldquo;EL TORNEO&rdquo;, oblig&aacute;ndose a sacar en<br />
paz y a salvo a GAMING TRAINERS S.A.P.I. DE C.V. y Mondelez M&eacute;xico S. de R.L. de C.V.<br />
en caso de cualquier reclamaci&oacute;n de terceros, derivadas de la originalidad o titularidad de<br />
dichas fotos, im&aacute;genes y/u obras.<br />
XIV. DERECHO DE ELIMINACI&Oacute;N PARTICIPACIONES FRAUDULENTAS.<br />
&ldquo;EL ORGANIZADOR&rdquo; se reserva el derecho de descalificar justificadamente al &ldquo;EL<br />
JUGADOR&rdquo; que defraude, altere o inutilice el buen funcionamiento, el transcurso normal y<br />
reglamentario de &ldquo;EL TORNEO&rdquo;; &ldquo;EL ORGANIZADOR&rdquo; pretende que &ldquo;EL JUGADOR&rdquo;<br />
compita en igualdad de condiciones en cada una de las din&aacute;micas y con estricto respeto a<br />
las normas de la buena fe. Por ello, cualquier utilizaci&oacute;n abusiva o fraudulenta de estas</p>

<p>bases o de las bases particulares o instrucciones especiales de una promoci&oacute;n, dar&aacute; lugar a<br />
la descalificaci&oacute;n de &ldquo;EL JUGADOR&rdquo; en cualquier promoci&oacute;n que realice dentro de &eacute;sta. Los<br />
jugadores no podr&aacute;n realizar declaraciones en medios de comunicaci&oacute;n, redes sociales o<br />
canales de comunicaci&oacute;n de la liga que tengan como fin generar un efecto perjudicial o<br />
da&ntilde;ino hacia la instituci&oacute;n organizadora o afiliados del torneo. En caso de presentarse una<br />
declaraci&oacute;n con esta connotaci&oacute;n, ser&aacute; considerado como actitud antideportiva grave.<br />
De este modo se entender&aacute; que se produce fraude, cuando:<br />
Varias personas participen con la misma cuenta.<br />
Una sola persona participe con diferentes cuentas.<br />
&ldquo;EL JUGADOR&rdquo; proporcione datos falsos o incompletos de su registro dentro de la p&aacute;gina.<br />
Repetidos intentos de introducir informaci&oacute;n, datos, c&oacute;digos, etc., falsos.<br />
&ldquo;EL JUGADOR&rdquo; cambie sus datos para poder participar en &ldquo;EL TORNEO&rdquo;.<br />
Se use o desarrolle cualquier tipo de programa inform&aacute;tico que facilite la<br />
introducci&oacute;n/validaci&oacute;n de informaci&oacute;n, datos, c&oacute;digos, etc., o la participaci&oacute;n automatizada<br />
en actividades o cualquier intento, abuso o acto de mala fe en el que se aproveche de &ldquo;EL<br />
TORNEO&rdquo; o se ponga a s&iacute; mismo en situaci&oacute;n privilegiada y cualquier otra actividad que<br />
intente alterar o altere la competencia justa con el objetivo de obtener premios<br />
indebidamente.<br />
Incumplir con los procedimientos expresados a continuaci&oacute;n y que se relacionan con el<br />
desarrollo de la transmisi&oacute;n y almacenamiento de contenido de cada equipo para fines de<br />
contenido exclusivo de &ldquo;EL ORGANIZADOR&rdquo;.<br />
XV. LIMITANTES Y CARGOS EXTRAS AL PREMIO.<br />
a) &ldquo;EL ORGANIZADOR&rdquo; no se hace responsable de los gastos en que el ganador incurra<br />
por participar en &ldquo;EL TORNEO&rdquo;, y/o para recoger y/o hacer v&aacute;lido su premio, ni de ninguna<br />
&iacute;ndole que no est&eacute; expresamente contemplado en los presentes T&eacute;rminos y Condiciones.<br />
b) &ldquo;EL ORGANIZADOR o el &ldquo;PATROCINADOR&rdquo; no se hacen responsables por ning&uacute;n<br />
impuesto estatal o federal que genere con motivo de la obtenci&oacute;n del Premio por parte del<br />
Ganador o comisi&oacute;n bancaria. Cualquier contribuci&oacute;n que se genere por este supuesto ser&aacute;<br />
responsabilidad &uacute;nica y exclusiva del ganador.<br />
c) &ldquo;EL ORGANIZADOR&rdquo; se reserva el derecho a cambiar los premios por otros de valor y<br />
caracter&iacute;sticas similares.<br />
d) La negativa expresa o falta de respuesta para validar los datos de participaci&oacute;n e<br />
identificaci&oacute;n despu&eacute;s de 1 (un) d&iacute;a h&aacute;bil de haber sido publicado el nombre de los<br />
ganadores, otorga a &ldquo;EL ORGANIZADOR&rdquo; el derecho de realizar la asignaci&oacute;n del premio a<br />
una nueva selecci&oacute;n de &ldquo;EL JUGADOR&rdquo; o disponer del Premio en cuesti&oacute;n como mejor<br />
convenga a sus intereses.<br />
e) En caso de que el Ganador decidiera no hacer uso del Premio por las razones que a &eacute;l<br />
convengan, el Premio se entregar&aacute; entonces a &ldquo;EL JUGADOR&rdquo; que conforme a la mec&aacute;nica<br />
sea elegible para ello, dando preferencia a &ldquo;EL JUGADOR&rdquo; que haya quedado, en su caso,<br />
en el lugar inmediatamente inferior al del Ganador que ha decidido no ejercer su premio.</p>

<p>f) &ldquo;EL JUGADOR&rdquo; renuncia expresamente, al aceptar las bases de &ldquo;EL TORNEO&rdquo; con su<br />
participaci&oacute;n, a cualquier cuestionamiento sobre los criterios adoptados, prevaleciendo<br />
siempre el criterio de &ldquo;EL ORGANIZADOR&rdquo;.<br />
g) Toda decisi&oacute;n de &ldquo;EL ORGANIZADOR&rdquo; ser&aacute; inapelable y definitiva.<br />
XVI. PUBLICACI&Oacute;N DE RESULTADOS.<br />
1. Una vez verificados los datos de los ganadores y que &eacute;stos hayan confirmado en tiempo<br />
y forma que su participaci&oacute;n es v&aacute;lida, ser&aacute;n ganadores definitivos (los &ldquo;Ganadores&rdquo; o<br />
&ldquo;Ganador&rdquo;).<br />
2. Se publicar&aacute;n los ganadores de alguno de los premios en el Sitio Rivals a partir del 17 de<br />
diciembre del 2022. Los premios se enviar&aacute;n por correspondencia y se realizar&aacute;n en un<br />
per&iacute;odo m&aacute;ximo de 60 d&iacute;as h&aacute;biles despu&eacute;s de dicha publicaci&oacute;n.<br />
XVII. CONDICIONES Y RESTRICCIONES.<br />
a) Cualquier ataque o intento de alteraci&oacute;n o manipulaci&oacute;n al sistema inform&aacute;tico o al sitio<br />
web o al lugar en el cual se encuentre radicada la misma, genera la descalificaci&oacute;n<br />
inmediata de &ldquo;EL JUGADOR&rdquo;.<br />
b) &ldquo;EL JUGADOR&rdquo; deber&aacute; evitar en cualquier momento conductas difamatorias o injuriosas<br />
dentro de su participaci&oacute;n y ser&aacute;n responsables de las faltas en que incurran.<br />
c) &ldquo;EL ORGANIZADOR&rdquo; no ser&aacute; responsable por cualquier da&ntilde;o o perjuicio originado por la<br />
imposibilidad o falta de resultados en el intento de contacto con &ldquo;EL JUGADOR&rdquo;, as&iacute; como<br />
por la aceptaci&oacute;n del premio.<br />
d) No ser&aacute;n v&aacute;lidas las participaciones llevadas a cabo fuera de los plazos especificados,<br />
siendo las mismas descalificadas de inmediato por no cumplir con los criterios para la<br />
selecci&oacute;n de ganadores.<br />
e) &ldquo;EL JUGADOR&rdquo; declarado como ganador deber&aacute; seguir todas las instrucciones que &ldquo;EL<br />
ORGANIZADOR&rdquo; le indique o el premio le ser&aacute; negado.<br />
f) Los premios son intransferibles, el ganador recibir&aacute; su premio en domicilio previamente<br />
enviado por &ldquo;EL ORGANIZADOR&rdquo;. Esta ubicaci&oacute;n ser&aacute; compartida desde el mismo correo<br />
electr&oacute;nico donde el usuario hizo su registro. Los premios no ser&aacute;n canjeables, endosables,<br />
sustituibles ni redimibles. En consecuencia, bajo ninguna circunstancia, el ganador podr&aacute;<br />
solicitar a &ldquo;EL ORGANIZADOR&rdquo; que los Premios sean entregados a una tercera persona ni<br />
les ser&aacute;n canjeados por ning&uacute;n otro bien.<br />
g) Por lo anterior, &quot;EL ORGANIZADOR&quot; y el &ldquo;Patrocinador&rdquo; se reserva el derecho de<br />
modificar, cambiar, cancelar, prolongar, disminuir, posponer o anular las vigencias,<br />
requisitos, t&eacute;rminos y condiciones, as&iacute; como los premios a entregar con motivo de &quot;EL<br />
TORNEO&quot;, con solo la menci&oacute;n en las bases, se aplicar&aacute;n estos cambios, (se les recuerda<br />
a &ldquo;LOS JUGADORES&rdquo; que los cambios en este documento son constantes y deben estar</p>

<p>pendiente de &eacute;l), previa autorizaci&oacute;n escrita por parte de GAMING TRAINERS SA DE CV o<br />
si a su juicio existieran elementos que impidan su realizaci&oacute;n.<br />
Estas bases se rigen por la Legislaci&oacute;n y Tribunales de M&eacute;xico, de la Ciudad de M&eacute;xico.<br />
&ldquo;EL TORNEO&rdquo; no involucra el azar, la obtenci&oacute;n de los Premios est&aacute; basada &uacute;nicamente en<br />
la habilidad de cada uno de los &ldquo;JUGADORES&rdquo;.<br />
XVIII. Entrega del coleccionable digital.<br />
A cada JUGADOR que haya alcanzado la segunda fase del torneo, como tambi&eacute;n a los<br />
ganadores del mismo, se les enviar&aacute; a la direcci&oacute;n de correo electr&oacute;nico con el cual se<br />
registr&oacute; al Torneo, la posibilidad de reclamar un coleccionable digital del Proof of Attendance<br />
Protocol (en adelante &ldquo;un POAP&rdquo;), el cual podr&aacute; ser reservado y asociado a una direcci&oacute;n<br />
de correo electr&oacute;nico y/o creado y conservado en una billetera digital seg&uacute;n la elecci&oacute;n del<br />
JUGADOR en caso de contar con una.<br />
Dicho POAP es una prueba digital de participaci&oacute;n, esta pieza digital es un non fungibles<br />
tokens est&aacute;ndar en el ecosistema de Ethereum bajo la norma ERC-721. Los POAPs son<br />
creados en una &quot;cadena paralela&quot; (en lo sucesivo &ldquo;sidechain&rdquo;) de Etheureum llamada<br />
Gnosis Chain. Al momento que el JUGADOR o PARTICIPANTE reclame el POAP acepta<br />
que no deber&aacute; vender o especular de manera comercial con el mismo. El POAP es un bien<br />
de uso exclusivo personal y no permite ning&uacute;n otro uso que el de una prueba de<br />
participaci&oacute;n en el Torneo. Usted acepta conservar dicho POAP dentro de la sidechain de<br />
Genosis Chain, as&iacute; mismo entiende que el POAP otorgado tiene el fin de pertenecerle a un<br />
&uacute;nico poseedor, por lo que el JUGADOR o PARTICIPANTE no deber&aacute; transferir o<br />
comercializar de forma alguna el POAP otorgado.<br />
El proceso de creaci&oacute;n de dichos POAPs no le generara ning&uacute;n costo adicional al<br />
participante siempre y cuando el POAP se conserve en la sidechain mencionada<br />
anteriormente.<br />
En caso de que el participante decidiera incumplir con estos t&eacute;rminos y condiciones, el<br />
POAP otorgado dejara de existir y ser&aacute; eliminado, el proceso de eliminaci&oacute;n del PAOP se<br />
har&aacute; sin previo aviso de manera inmediata y de manera permanente, quedando libre de<br />
responsabilidad tanto el ORGANIZADOR como el Patrocinador de cualquier reclamaci&oacute;n.<br />
El periodo para descargar el POAP ser&aacute; de 1 (uno) semana contada a partir de la recepci&oacute;n<br />
del correo electr&oacute;nico con el acceso al mismo. El env&iacute;o se har&aacute; al correo electr&oacute;nico con el<br />
cual se registr&oacute; a EL TORNEO o bien le comparti&oacute; al organizador.<br />
La entrega del POAP no implica de ninguna forma actividades de azar, ya que la obtenci&oacute;n<br />
del POAP est&aacute; basada &uacute;nicamente en la prueba de participaci&oacute;n los participantes en el<br />
Torneo.</p>

<p>XIV. DUDAS O COMENTARIOS.<br />
En caso de experimentar cualquier inconveniente o inconformidad, &ldquo;EL JUGADOR&rdquo; podr&aacute;<br />
reportarlo al correo electr&oacute;nico de &ldquo;EL ORGANIZADOR&rdquo; contacto@esporst-latm.com de<br />
para poder dar seguimiento a su caso y/o para solicitar informaci&oacute;n.<br />
As&iacute; mismo, &ldquo;EL JUGADOR&rdquo; podr&aacute; ponerse en contacto con &ldquo;EL ORGANIZADOR&rdquo; por medio<br />
de un link ubicado en el Sitio Rivals que podr&aacute; encontrar los medios determinados para que<br />
&ldquo;EL JUGADOR&rdquo; pueda reportar su caso.</p>


			</div>
			<div class="modal-footer"></div>
		</div>
	</div>
</div>
</template>

<script>
	
	export default {
		mounted() {
			this.showRegisterSuccessPopup();
			
		},
		
		methods: {
			gotoHome() {
				window.location.href="/";
			},
			showRegisterSuccessPopup() {
				
				document.getElementById("registro_popup_btn").click();
			}
		}
	}

</script>

<style scoped>
	.modal-header, .modal-footer {
			background-image: url('../assets/rivals/arriba_mensaje.png');
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 63px;
		}

		.modal-title {
			width: 100%;
		}

		.modal-body {
			font-family: "Arial";
			font-size: 1.2rem;
			padding: 3vw 6vw;
			color: black;
			text-align: left;
		}

		.modal-body h1, .modal-body span, .modal-body strong, .modal-body p{
			font-size: 1.2rem;
			font-family: "Arial";
			font-weight: bold;
		}

		.modal-body p {
			font-weight: normal;
		}

		#modal_link {
			margin: 40px;
			word-break: break-all;
		}
</style>