<template>
<!-- Registro exitoso Modal -->
<!-- Button trigger modal -->
<button id="transmition_popup_btn" style="display: none" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#transmitionModal">
</button>
<canvas id="my-canvas"></canvas>
<div class="transmition-box section" style="">
	<div style="position: relative;">
		<img style="width: 100%; position: absolute; pointer-events: none; left: 0; top: 0; z-index: 0;" src="../assets/rivals/transmision/transmision_bg_complete.jpg">
		
	</div>
	<div style="text-align: justify;">
		<h3 class="title" style="text-align: center;"></h3>
		<div class="video-wrapper">
			<div style="position:relative;padding-top:56.25%;">
				<!--<video-js id="transmition_video" controls autoplay />-->
				<iframe id="transmition_video" style="position:absolute;top:0;left:0;width:100%;height:100%; border:6px solid var(--pink); z-index: 0; " src="https://www.youtube.com/embed/7o342f7qy0k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

			</div>

		</div>
		<button class="celebra-btn" @click="showConfetti">CELEBRA</button>
	</div>

</div>

<img src="https://rivalstrident.com/bocas/confeti_1.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_2.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_3.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_4.png" style="display: none">
<img src="https://rivalstrident.com/bocas/confeti_5.png" style="display: none">

<div class="modal fade" id="transmitionModal" tabindex="-1" aria-labelledby="transmitionModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title fs-5" id="transmitionModalLabel">INTRODUCE TU CODIGO</h1>
				<button style="display: none;" id="close_modal_btn" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<input id="code_input" type="text" placeholder="CODIGO" />
			</div>
			<div class="modal-footer"><button @click="checkIfValid">ENVIAR</button></div>
		</div>
	</div>
</div>
</template>

<script>

	//import videojs from 'video.js';
	import axios from 'axios';
	import ConfettiGenerator from "confetti-js";
	
	export default {
		data() {
			return {
				isValid: false
			}
		},
		mounted() {
			this.showRegisterSuccessPopup();
			document.getElementById("header").style.display = "none";
			//document.getElementById("footer").style.display = "none";
		},
		methods: {
			showConfetti(){
				var confettiElement = document.getElementById('my-canvas');
				var confettiSettings = {
					target: confettiElement,
					respawn: false,
					start_from_edge: false,
					"props": [
						"circle",
						"square",
						{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_1.png", "size": 60 },
						{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_2.png", "size": 60 },
						{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_3.png", "size": 60 },
						{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_4.png", "size": 60 },
						{ "type": "svg", "src": "https://rivalstrident.com/bocas/confeti_5.png", "size": 60 }
					]
				};
				var confetti = new ConfettiGenerator(confettiSettings);
				confetti.render();
			},
			showRegisterSuccessPopup() {
				//document.getElementById("transmition_popup_btn").click();
			},
			checkIfValid() {
				const fd = new FormData();
				fd.append('code', document.getElementById("code_input").value);
				fd.append('agent', navigator.userAgent);
				let self = this;
				axios.post('https://monstar.io/api/gsheets/rivals2022_validateCode.php', fd)
						.then(function (response) {
							document.getElementById("code_input").value = '';
							if(response.data.message !== "success")
							{
								alert('Código no valido.');
							} else {
								document.getElementById("close_modal_btn").click();

								self.isValid = true;
								/*videojs('transmition_video').ready(function(){
									this.src({
										src: response.data.url,
										type: response.data.type
									});
								});*/
							}
						});
			}
		}
	}

</script>

<style scoped>

	body {
		background-image: url('../assets/rivals/transmision/transmision_bg_complete.jpg');
		background-size: contain;
	}

	#my-canvas {
		position: fixed;
		z-index: 50;
		left: 0;
		top: 0;
		user-select: none;
		pointer-events: none;
	}

	.celebra-btn {
		display: block;
		font-family: Montserrat;
		text-decoration: none;
		font-size: 1.5rem;
		padding: 10px 20px;
		background-color: white;
		outline: none;
		border: 3px solid black;
		color: black;
		margin: 10px;
		box-sizing: border-box;
		z-index: 100;
		position: relative;
		margin: 0 auto;
		margin-top: 2vw;
	}

	.celebra-btn:hover {
		box-shadow: 0 0 20px var(--pink);
	}

	#footer {
		margin-top: 30vh !important;
	}

	.modal input {
		text-align: center;
	}

	.video-wrapper {
		width: 70vw;
		margin: 0px auto;
		padding-top: 50px;
	}

	#transmition_video {
		width: 100%;
		height: 100%;
		z-index: 0;
		position: absolute;
		top:7vw;
		left:0;
		border: 6px solid transparent;
		border-radius: 5px;
		border-image: linear-gradient(to bottom right, #0edd99, var(--pink));
		border-image-slice: 1;
		box-shadow: 0 0 40px var(--pink);
	}

	.modal-footer {
		justify-content: center;
		
	}

	.modal-footer button {
		padding: 5px 10px;
	}

	.modal-header, .modal-footer {
			background-image: url('../assets/rivals/arriba_mensaje.png');
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 63px;
		}

		.modal-title {
			width: 100%;
		}

		.modal-body, button {
			font-family: "Montserrat";
			font-size: 1.2rem;
			padding: 3vw 6vw;
			color: black;
		}

		#modal_link {
			margin: 40px;
			word-break: break-all;
		}

		@media only screen and (max-width: 991px) {
			.video-wrapper {
				width: 100vw;
			}
		}
</style>